.org-list {
  .org-item {
    h5 {
      margin-bottom: 0;
    }
    .team-count {
      font-size: 0.9em;
      color: $body-subdued-font-color;
    }
    .actions {
      white-space: nowrap;
    }
  }

  .org-item-body {
    display: flex;
    justify-content: space-between;
  }
  .org-item-header {
    display: flex;
    justify-content: space-between;

    .delete-btn {
      display: none;
      font-size: 24px;
      position: relative;
      top: -25px;
      right: -10px;
      color: gray;
    }
  }
}

@media (max-width: 500px) {
  .org-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
.orgs-panel-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.manage-org {
  .org-mgmt-tree {
    margin: 0 10px;

    .row {
      display: grid;
      grid-template-columns: auto 37px 1fr;
      align-items: center;
      column-gap: 5px;

      &:hover {
        background: rgb(244, 243, 243);
        .actions {
          visibility: visible;
        }
      }

      &.with-menu-open {
        .actions {
          visibility: visible;
        }
      }

      &.editing {
        border: 1px solid $info-color;
        .gutters .gutter {
          border: none !important;
        }
        .actions {
          visibility: hidden;
        }
      }

      & > div {
        padding-top: 3px;
        padding-bottom: 3px;
      }

      .icons {
        text-align: right;
      }

      .name-form {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        align-items: center;
      }

      .gutters {
        padding: 0;
        display: flex;
        height: 100%;
        margin-top: -20px;
        .gutter {
          height: 100%;
          width: 15px;
          &.org-level {
            border-right: 2px solid lighten($green, 40%);
          }
          &.group-level {
            border-right: 2px solid lighten($info-color, 30%);
          }
        }
      }
    }
    .row-icon {
      color: var(--gray);
    }

    .folder-toggle {
      margin: 0;
    }

    .folder-icon {
      font-size: 20px;
      color: $blue;
    }

    .org-icon {
      color: $green;
    }

    .team-icon {
      color: var(--gray);
      font-size: 14px;
    }

    .row-content {
      display: flex;
      column-gap: 10px;
      align-items: center;

      .actions {
        visibility: hidden;

        display: grid;
        grid-template-columns: 35px 35px;
        align-items: center;
        column-gap: 1px;

        .dropdown.show > button {
          color: gray !important;
          background: lighten(gray, 40%) !important;
        }

        button:not(.dropdown-item) {
          color: gray;
          margin: 0;
          padding: 3px 7px !important;
          background: transparent;
          &:focus {
            color: gray !important;
            outline: none !important;
          }
          &:hover,
          &:active {
            color: gray !important;
            background: lighten(gray, 40%) !important;
            outline: none !important;
          }
        }
      }
    }
    .tree-icon {
      color: $body-subdued-font-color;
    }
  }
}

.org-change-warning {
  display: flex;
  column-gap: 10px;
  svg {
    margin-top: 7px;
  }
}

@media (max-width: 600px) {
  .row-content {
    justify-content: space-between;
    .actions {
      visibility: visible !important;
    }
  }
}
