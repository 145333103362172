@media (min-width: 576px) {
  .form-inline .autocomplete .form-control {
    width: 300px;
  }
}

.autocomplete {
  position: relative;

  .loading {
    text-align: center;
    padding-top: 0.5em;
  }
  .highlight {
    font-weight: bold;
  }
}

.autocomplete-menu {
  .autocomplete-items {
    display: block;
  }
  .autocomplete-loading {
    display: none;
  }

  &.loading {
    .autocomplete-items {
      display: none;
    }
    .autocomplete-loading {
      display: block;
    }
  }
}
@-webkit-keyframes autocomplete-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes autocomplete-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.autocomplete-menu-outer {
  top: 105%;
  max-width: 94%;
  width: 94%;
  right: 3%;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  border-radius: 0.125rem;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  background-color: #fff;
  box-sizing: border-box;
  max-height: 200px;
  position: absolute;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 5px;

  &:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: '';
    top: -5px;
    left: 10px;
    right: auto;
    color: rgb(190, 24, 24);
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }

  &:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px;
  }
}
.autocomplete-menu {
  max-height: 198px;
  overflow-y: auto;
}
.autocomplete-option {
  font-size: 0.8571em;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-top: 5px;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: block;
  outline: none;
  color: #292b2c;
}
.autocomplete-option.is-selected {
  background-color: $medium-gray;
}
.autocomplete-option.is-focused {
  background-color: $smoke-bg;
}
.autocomplete-option.is-disabled {
  color: #cccccc;
  cursor: default;
}
.autocomplete-noresults {
  box-sizing: border-box;
  color: #999999;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
