.live-assessment-detail-chart {
  .chart-footer {
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: 0.25rem;
    color: $blue;

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 1;
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
      font-size: 0.5rem;

      @media #{$medium-up} {
        font-size: 0.8rem;
      }
    }
  }

  .question-labels {
    display: none;
    margin: 0.5rem 0 0;
    padding: 0;
    width: 100%;

    .question-label {
      margin: 0;
      padding: 0.2em;
      list-style: none;
      text-align: center;
      font-size: 0.6rem;
      flex: 1 0 0px;
      flex-basis: 1;
      word-break: keep-all;
      hyphens: auto;
    }
  }

  .color-scale {
    display: none;
  }

  .no-response-marker {
    .lollipop-pop {
      fill: rgba($no-response-marker-color, 0.5);
    }

    .lollipop-stem {
      display: none;
    }
  }
}

@media #{$medium-up} {
  .live-assessment-detail-chart {
    .color-scale {
      display: inline;
    }
    .question-labels {
      display: flex;

      .question-label {
        font-size: 0.8rem;
      }
    }
  }
}

.live-assessment-review-detail {
  .participant-card .card-title {
    margin: 0;
  }
  .participant-list {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;

    .participant {
      list-style: none;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin-right: 0.5em;
      }
    }
  }

  .question-results-summary {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;

    .question-result .question-overview {
      margin-bottom: 0;
    }
  }
}

@media #{$screen} and (min-width:992px) and (max-width:1192px) {
  .live-assessment.live-assessment-review-detail .question-overview {
    font-size: 0.8rem;
  }

  .sidebar-mini
    .live-assessment.live-assessment-review-detail
    .question-overview {
    font-size: 1rem;
  }
}
