.join-assessment-form {
  // label { margin-bottom: .25em; }
  input[type='text'] {
    width: 100%;
    max-width: 100%;
  }

  @media #{small-up} {
    input[type='text'] {
      width: auto;
    }
  }

  @media #{$medium-up} {
    input[type='text'] {
      width: 100%;
    }
  }

  // .btn { margin-top: 0;}
}
