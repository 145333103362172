.assessment-type-glyph {
  &.assessment-type-live {
    color: $green;

    .roundel {
      color: $green;
    }
    .roundel-fill {
      color: rgba($blue, 0.5);
    }
    .background-layer {
      color: $yellow;
    }
  }

  &.assessment-type-survey {
    color: $green;

    .roundel {
      color: $green;
    }
    .roundel-fill {
      color: rgba($blue, 0.5);
    }
    .background-layer {
      color: #fbead3;
    }
  }
}
