/*!

 =========================================================
 * Now UI Dashboard PRO React - v1.1.0 based on Now UI Dashboard PRO - v1.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/now-ui-dashboard
 * Copyright 2017 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard/blob/master/LICENSE.md)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'now-ui-dashboard/variables';
@import 'now-ui-dashboard/mixins';

// Plugins CSS
@import "now-ui-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "now-ui-dashboard/plugins/plugin-bootstrap-switch";
@import "now-ui-dashboard/plugins/plugin-card-wizard";
@import "now-ui-dashboard/plugins/plugin-datatables.net";
@import "now-ui-dashboard/plugins/plugin-nouislider";
@import "now-ui-dashboard/plugins/plugin-perfect-scrollbar";
@import "now-ui-dashboard/plugins/plugin-react-big-calendar";
@import "now-ui-dashboard/plugins/plugin-react-bootstrap-sweetalert";
@import "now-ui-dashboard/plugins/plugin-react-bootstrap-switch";
@import "now-ui-dashboard/plugins/plugin-react-datetime";
@import "now-ui-dashboard/plugins/plugin-react-select";
@import "now-ui-dashboard/plugins/plugin-react-simple-maps";
@import "now-ui-dashboard/plugins/plugin-react-table";
@import "now-ui-dashboard/plugins/plugin-react-tagsinput";


// Core CSS
@import "now-ui-dashboard/buttons";
@import "now-ui-dashboard/inputs";
@import "now-ui-dashboard/checkboxes-radio";
@import "now-ui-dashboard/progress";
@import "now-ui-dashboard/badges";
@import "now-ui-dashboard/pagination";
@import "now-ui-dashboard/typography";
@import "now-ui-dashboard/misc";
@import "now-ui-dashboard/info-areas";
@import "now-ui-dashboard/pills";

// components
@import "now-ui-dashboard/fileupload";
@import "now-ui-dashboard/social-buttons";
@import "now-ui-dashboard/tabs";
@import "now-ui-dashboard/navbar";
@import "now-ui-dashboard/dropdown";
@import "now-ui-dashboard/alerts";
@import "now-ui-dashboard/images";
@import "now-ui-dashboard/popups";
@import "now-ui-dashboard/nucleo-outline";
@import "now-ui-dashboard/modals";
@import "now-ui-dashboard/carousel";
@import "now-ui-dashboard/tables";
@import "now-ui-dashboard/sidebar-and-main-panel";
@import "now-ui-dashboard/cards";
@import "now-ui-dashboard/footers";
@import "now-ui-dashboard/timeline";
@import "now-ui-dashboard/fixed-plugin";

// example pages and sections
@import "now-ui-dashboard/example-pages";
@import "now-ui-dashboard/sections";


@import "now-ui-dashboard/responsive";
