.live-assessment {
  .assessment-review-header {
    .question-selector {
      padding: 0 2.5px;
    }

    .details-filter-selector {
      margin-bottom: -40px;
    }

    .question-overview {
      .question-title {
        font-size: 0.5rem;
      }

      @media #{$large-up} {
        .question-title {
          display: block;
        }
      }

      @media #{$xlarge-up} {
        .question-title {
          font-size: 0.8rem;
        }
      }
    }
  }
}
