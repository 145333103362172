.mainPanel {
  position: relative;
  :global(.panel-header) {
    padding-top: 40px;
  }
  :global(.panel-header-sm) {
    height: auto;
  }
  :global(.content) {
    padding: 0 15px;
    margin-top: -30px;
  }
}
