.category-selector {
  display: inline-block;

  :global(.combobox-items) {
    max-height: 200px;
    overflow-y: auto;
    max-width: 400px;
    width: auto;
  }
}

.label {
  position: relative;
  display: inline-block;
  max-width: 100%;
  line-height: 1.35em;

  input[type='text'] {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 2px 10px;
    color: inherit;
    width: 100%;
    border-radius: 15px;
  }
}

.post-match,
.pre-match,
.match {
  white-space-collapse: preserve;
}
.match {
  font-weight: bold;
}

.sizing {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  white-space-collapse: preserve;
  color: transparent;
}
