@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.editor-errors-popover :global(.popover) {
  // width: 600px;
  max-width: 600px;
  font-size: bs.$font-size-small;
}

.error-group {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    font-size: 0.6rem;
    margin: 0;
  }
}

.error-group-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-style: italic;
}
