@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.assessment-differential {
  display: grid;

  grid-template-areas: 'chart selector';
  grid-template-columns: 1fr 200px;
  gap: 1rem;
}

.chart {
  grid-area: chart;
}

.selector {
  grid-area: selector;
  font-size: 0.8rem;
}

.btn {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  color: white;
  margin: 0 -1rem;
  text-align: left;

  &:hover {
    background-color: rgba(bs.$orange, 0.2);
  }

  &.start-range,
  &.end-range {
    svg {
      opacity: 1;
    }
  }
  &.within-range {
    color: bs.$faded-blue;

    &:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: rgba(bs.$faded-blue, 0.5);
      position: absolute;
      top: 0;
      left: 0.5em;
    }
  }
  &.start-range {
    color: bs.$blue;

    &:before {
      height: 25%;
      top: 75%;
    }

    svg {
      color: bs.$orange;
    }
  }
  &.end-range {
    color: bs.$blue;

    &:before {
      height: 25%;
    }
  }

  svg {
    position: relative;
    left: -1.25em;
    margin-right: -1.25em;
    opacity: 0;
  }
}

.differential-item {
  display: inline-block;
  font-size: 0.6rem;
  width: 80px;
  text-align: center;
  vertical-align: top;
  position: relative;
}

.item-label {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 0.25em;
  -webkit-line-clamp: 2;
}

.no-assessment-selected {
  max-width: 600px;
  margin: 3em auto;
}

.glyph {
  .from {
    fill: none;
    stroke: bs.$orange;
    stroke-width: 1.5;
  }
  .to {
    fill: bs.$blue;
    stroke: bs.$blue;
    stroke-width: 1.5;
  }
  .connector {
    stroke: bs.$no-response-marker-color;
    stroke-width: 1;
  }
  .arrow {
    fill: bs.$no-response-marker-color;
  }
}
