@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  border: 2px solid bs.$background-impression-color;
  padding: 20px;
  max-width: 350px;
  h2 {
    margin: 0;
  }
  position: relative;
}

.price {
  color: bs.$blue;
  margin-bottom: 20px;
  min-height: 5em;

  .amount {
    font-size: 32px;
  }
}

.term {
  color: bs.$blue;
  display: block;
}

.description {
  color: bs.$body-subdued-font-color;
}

.lightMode {
  color: #2c2c2c;

  .description {
    color: #2c2c2c;
  }

  .price {
    filter: brightness(90%);
  }
}

.currentBadge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border-left: 2px solid bs.$background-impression-color;
  border-bottom: 2px solid bs.$background-impression-color;
  border-bottom-left-radius: 5px;
  background-color: bs.$purple;
}
