@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.revision-fieldset {
  border: 1px solid bs.$green;
  border-radius: bs.$border-radius-small;
  padding: 0 3px;
  margin: 0.5rem -4px;

  legend {
    font-size: 0.6rem;
    width: min-content;
    padding: 0 0.25rem;
    margin-bottom: 0;
    margin-left: 0.25rem;
    color: bs.$green;
  }

  .revision {
    margin: 0.15rem 0;
  }
}

.revisions-empty {
  border-radius: bs.$border-radius-small;
  font-size: 0.8rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.revisions {
  position: relative;

  :global(.loading) {
    position: absolute;
    top: 0.5rem;
    right: 0;
    color: bs.$body-subdued-font-color;
    text-align: center;
  }
}

.revision {
  display: grid;
  grid-template:
    'published-by version'
    'published-by date';

  grid-template-columns: min-content 1fr;
  column-gap: 0.5rem;
  margin: 0.5rem 0;
}
.published-by {
  grid-area: published-by;
  align-content: center;

  .published-by-identifier {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-color: bs.$blue;
    color: white;
    border-radius: 50%;
  }
}

.revision-version {
  grid-area: version;
  font-size: 0.6rem;
  color: bs.$body-subdued-font-color;
  margin-bottom: -0.3rem;
}
.revision-published-at {
  grid-area: date;
  font-size: bs.$font-size-small;
}
