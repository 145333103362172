.org-data {
  thead .recommendation-composite > div {
    font-size: 0.7rem;
    font-weight: normal;
    border-bottom: 1px solid $body-subdued-font-color;
  }
  .recommendation-composite {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    width: 100%;

    svg {
      height: 20px;
    }
  }
  table {
    width: 100%;
  }
  td {
    font-size: 0.8rem;
  }

  td:first-child {
    width: 200px;
    max-width: 200px;
    text-align: right;
  }
  div.score {
    height: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    color: $body-subdued-font-color;

    &.no-scored-results {
      background-color: $smoke-bg;
    }
  }
}

.org-top-recommendations {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  column-gap: 1em;
  row-gap: 1em;

  h3 {
    font-size: 1.2rem;
    margin: 0 0 0.4rem;
    border-bottom: 1px solid #2c2c2c;
  }

  .subtitle {
    font-size: 65%;
    display: inline-block;
    margin-left: 2em;
  }

  .org-node-recommendation.parent-org {
    .subtitle {
      width: 100%;
      display: flex;
      margin-left: 0;
      column-gap: 1em;

      .descriptor {
        flex-basis: content;
      }
      .dimension-descriptor {
        flex-basis: auto;
        text-align: right;
        flex-grow: 1;

        .icon {
          margin-left: 0.3em;
        }
      }
    }
  }

  .top {
    font-weight: bold;
  }
  .top::before {
    content: '* ';
  }

  .no-data {
    font-style: italic;
    text-align: center;
    font-weight: lighter;
    padding: 10px 0;
  }

  .scored-name,
  .scored-name button {
    text-align: right;
    vertical-align: top;
    color: $body-color;
    &:hover {
      color: $body-color;
    }
    &:focus {
      color: $body-color;
      text-decoration: none;
    }
  }

  .sub-scores {
    .score {
      height: 6px;
      margin: 5px 0;
    }
    .unscored {
      font-style: italic;
      color: $text-muted;

      .score {
        opacity: 0.5;
      }
    }

    .unscored-label {
      font-size: 0.6rem;
      color: $body-subdued-font-color;
    }
  }
  .rec-results-dist-bounds {
    stroke: $chart-stroke-color;
    stroke-width: 0.5;
  }
  .rec-results-dist-unscored {
    fill: $body-subdued-font-color;
    font-size: 0.8rem;
  }
}
