.chart-glyph {
  width: 24px;
  height: 24px;
  .border {
    stroke: $blue;
    stroke-width: 1;
    fill-opacity: 0;
  }
}

.survey-review-detail {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 99%));
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;

  .question-results {
    font-size: 0.8rem;
  }
}

.survey-review-overview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 99%));
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;

  .question-results {
    font-size: 0.8rem;
  }
}

.survey-review-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 99%));
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
}

@media #{$medium-up} {
  .survey-review-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 48%));
  }
}

@media (min-width: 1400px) {
  .survey-review-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 32%));
  }
}

@media #{$xxlarge-up} {
  .survey-review-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 48%));
  }
}

@media (min-width: 2500px) {
  .survey-review-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 32%));
  }
}

.detail-overview-question-header {
  display: grid;
  grid-template-columns: 1fr max-content;
  margin: 1rem 0;

  .question-set-name {
    align-self: end;
    margin-bottom: 0;
  }
  .question-score {
    align-self: end;
    font-weight: bold;
    line-height: 1.2;
  }
}
