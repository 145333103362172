@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    // box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  }

  :global(.card-header) {
    padding: 0;
    background-color: transparent;
    border-bottom: 3px solid bs.$smoke-bg;
  }

  :global(.card-body) {
    background-color: bs.$white-bg;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

.feature-card2 {
  display: grid;
  grid-template-columns: 1fr 1rem 1fr;
  align-items: center;
  gap: 1rem;
  grid-template-areas: 'image gap body';

  &:nth-of-type(even) {
    grid-template-areas: 'body gap image';

    @media (min-width: 3000px) {
      grid-template-areas: 'image gap body';
    }
  }
}

@media (max-width: 674px) {
  .feature-card2,
  .feature-card2:nth-of-type(even) {
    row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'gap'
      'body';

    .content {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 3px solid bs.$purple;
    }
  }

  .gap {
    position: relative;
    height: 0;

    &:before {
      content: none;
    }
  }
}

.gap {
  position: relative;
  height: 100%;

  &:before {
    content: '';
    display: block;
    background-color: bs.$purple;
    width: 3px;
    height: 80%;
    position: absolute;
    top: 10%;
    left: calc(50% - 1.5px);
    border-radius: 1.5px;
  }
}

.image {
  grid-area: image;
  text-align: center;

  img {
    max-width: 500px;
    width: 100%;
  }
}
.body {
  grid-area: body;
}

.content {
  max-width: 500px;
  margin: 0 auto;
}

.title {
  margin-top: 0;

  svg {
    // margin-right: 0.7rem;
    color: bs.$blue;
  }
}

.header-grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 0.7rem;
}
