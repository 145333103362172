.passphrase {
  font-size: 3rem;
  display: block;
  line-height: 1;

  min-height: 3rem;
  margin-bottom: 1rem;
}

@media #{$large-up} {
  .passphrase {
    min-height: 6rem;
  }
}

.qr {
  padding: 0 0 100% 0;
  background-color: $blue;
}

.join-link {
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 0.5em;
  word-wrap: break-word;

  input[readonly] {
    background-color: #fff;
    cursor: text;
    color: $body-color;
  }
}

.share-label {
  position: relative;
}
.share-links {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1.333rem;
}

.qr-code-border {
  background-color: $blue;
  border: 0.4rem solid $blue;
  font-size: 0;
}
.qr-code {
  width: 100%;
  height: auto;
  background: #fff;

  path {
    fill: #000;
  }
}
