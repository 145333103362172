@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.menu {
  padding: 1rem 0.5rem 1rem 1rem;
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 0.5rem;
}

.profile {
  .name,
  .username {
    max-width: 9rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .name {
    text-align: center;
  }
  .username {
    font-size: bs.$font-size-small;
    text-align: center;
    color: bs.$blue;
  }
  .avatar {
    background-color: bs.$dark-gray;
    border-radius: 50%;
    font-size: 2rem;
    width: 3em;
    height: 3em;
    display: grid;
    align-items: center;
    justify-items: center;
    color: #fff;
    margin: 0 auto 0.5rem;
    position: relative;

    &:before {
      $border-gap: 4px;
      content: '';
      border: 2px solid bs.$blue;
      position: absolute;
      top: -$border-gap;
      bottom: -$border-gap;
      left: -$border-gap;
      right: -$border-gap;
      border-radius: 50%;
    }
  }
}

.actions {
  min-width: 3rem;

  .account {
    background-color: bs.$smoke-bg;
    font-size: 0.8rem;
  }

  :global(.dropdown-item) {
    margin-top: 0;
    padding: 0;

    &.padded {
      padding: 0.6rem 1.5rem;
    }

    a:not(.btn):not(.dropdown-item) {
      color: #2c2c2c;
    }
  }

  a {
    padding: 0.6rem 1.5rem;
    &:hover {
      text-decoration: none;
    }
    display: block;
  }
}
