.wrapper{
    position: relative;
    top: 0;
    min-height: 100vh;
    overflow: hidden;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar{
    overflow-x: hidden;
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 260px;
    left: 0;
    z-index: 1030;

    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 62px);
        overflow: auto;
        width: 260px;
        z-index: 4;

        .dropdown .dropdown-backdrop{
          display: none !important;
        }

        .navbar-form{
            border: none;
        }

        > .nav,
        .user .info{
          [data-toggle="collapse"] ~ div > ul > li > a{
              span{
                display: inline-block;
                @extend .animation-transition-general;
              }

              .sidebar-normal{
                margin: 0;
                position: relative;
                transform: translateX(0px);
                opacity: 1;
                white-space: nowrap;
                display: block;
                z-index: 1;
                line-height: 23px;
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
              }

              .sidebar-mini-icon{
                text-transform: uppercase;
                width: 34px;
                margin-right: 10px;
                z-index: 1;
                margin-left: 0px;
                font-size: 12px;
                text-align: center;
                line-height: 25px;
                position: relative;
                float: left;
                display: inherit;
                line-height: 24px;
                color: $opacity-5;
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
              }

              i{
                font-size: 17px;
                line-height: 20px;
                width: 26px;
              }
            }
        }

        [data-toggle="collapse"] ~ div > ul > li.active > a{
            background-color: transparent;
            box-shadow: none;

          .sidebar-mini-icon{
            color: inherit;
          }

          &:after{
              content: "";
              position: absolute;
              background-color: $white-color;
              height: 44px;
              width: calc(100% - 5px);
              top: 0;
              border-radius: 30px;
              left: 2px;
              z-index: 0;
              box-shadow: $sidebar-active-box-shadow;
              @extend .animation-transition-general;
          }
        }
    }

    .navbar-minimize{
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1;

      @extend .animation-transition-general;
    }
    .logo-tim{
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img{
          width: 60px;
          height: 60px;
      }
    }

    .nav{
        margin-top: 20px;
        display: block;

        .caret{
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li{
            > a + div .nav li > a{
                margin-top: 7px;
            }

            > a,
            > a:not([href]):not([tabindex]):focus,
            > a:not([href]):not([tabindex]):hover{
                margin: 10px 15px 0;
                border-radius: $btn-round-radius;
                color: $white-color;
                display: block;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: $font-size-mini;
                padding: 10px 8px;
                line-height: $line-height-nav-link;
            }

            &:first-child > a{
                margin: 0 15px;
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a{
                background-color: $opacity-1;
            }

            &:hover:not(.active) > a i,
            &:focus:not(.active) > a i{
                color: $white-color;
            }

            &.active > a{
                background-color: $white-color;
                box-shadow: $sidebar-active-box-shadow;
                color: $primary-color;

                i{
                    color: $primary-color;
                }
            }

            &.active > a[data-toggle="collapse"]{
              background-color: $opacity-1;
              box-shadow: none;
              color: $white-color;

              i{
                color: $white-color;
              }
            }

            a[data-toggle="collapse"] ~ div li:not(.active) > a:after{
                content: "";
                position: absolute;
                width: calc(100% - 5px);
                background-color: transparent;
                height: 44px;
                top: 0;
                border-radius: 30px;
                left: 2px;
                z-index: 0;
                @extend .animation-transition-general;
            }

            a[data-toggle="collapse"] ~ div li:not(.active):hover > a{
                background-color: transparent;

                &:after{
                    background-color: $opacity-1;
                }
            }

        }

        p{
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            transition: transform 300ms ease 0s, opacity 300ms ease 0s;
        }

        i{
            font-size: 18px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $opacity-5;
            position: relative;
        }
    }

    .collapse .nav,
    .collapsing .nav{
      margin-top: 0;
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: 1;
        }
    }

    .logo{
        position: relative;
        padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;
        font-size: 1.5rem;

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 12px;
            margin-right: 10px;
            font-size: 1.8rem;
            position: relative;
            top: -.1rem;
        }

        a.logo-normal{
            font-family: "Harabara Mais", Roboto, "Helvetica Neue", Arial, sans-serif;
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $white-color;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after{
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &,
    &[data-color="black"]{
      @include sidebar-color($black-color);
    }
    &[data-color="blue"]{
      @include sidebar-color(darken($info-color, 8%));
    }
    &[data-color="green"]{
      @include sidebar-color($success-color);
    }
    &[data-color="orange"]{
      @include sidebar-color($orange-color);
    }
    &[data-color="red"]{
      @include sidebar-color($danger-color);
    }
    &[data-color="yellow"]{
      @include sidebar-color($warning-color);
    }

    .user{
        padding-bottom: 20px;
        margin:20px auto 0;
        position: relative;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;
        }

        .photo{
            width: 34px;
            height: 34px;
            overflow: hidden;
            float: left;
            z-index: 5;
            margin-right: 10px;
            border-radius: 50%;
            margin-left: 23px;
            box-shadow: $box-shadow-raised;
            @extend .animation-transition-general;

            img{
                width: 100%;
            }
        }

        a{
            color: $white-color;
            text-decoration: none;
            padding: $padding-base-vertical 15px;
            white-space: nowrap;
            @extend .animation-transition-general;
        }

        .info{
            > a,
            > a:not([href]):not([tabindex]):focus,
            > a:not([href]):not([tabindex]):hover{
               display: block;
               line-height: 18px;
               color: #FFFFFF;

                > span{
                   @extend .animation-transition-general;
                   display: block;
                   position: relative;
                   opacity: 1;
                }
            }

            .caret{
                position: absolute;
                top: 8px;
                right: 18px;
            }
        }
    }
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    position: relative;
    float: right;
    width: $sidebar-width;
    // width: 100%;
    background-color: $light-gray;
    background-color: #ebecf1;

    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    div > .content{
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px;
        margin-bottom: -30px;
    }

    > .navbar{
        margin-bottom: 0;
    }


    .header{
        margin-bottom: 50px;
    }
}





// windows os settings for perfect scrollbar off
.sidebar .sidebar-wrapper,
.main-panel{
    .perfect-scrollbar-on &,
    &{
        overflow: hidden;
    }
}

// regular settings for perfect scrollbar off
.perfect-scrollbar-off{
    .sidebar .sidebar-wrapper,
    .main-panel{
        overflow: auto;
    }
}

.sidebar,
.main-panel{
    height: 100%;
    max-height: 100;
}

@media (min-width: 991px) {
    .sidebar,
    .main-panel,
    .sidebar-wrapper{
        -webkit-transition-property: top,bottom,width;
        transition-property: top,bottom, width;
        -webkit-transition-duration: .2s,.2s, .35s;
        transition-duration: .2s,.2s, .35s;
        -webkit-transition-timing-function: linear,linear,ease;
        transition-timing-function: linear,linear,ease;
        -webkit-overflow-scrolling: touch;
    }

    .sidebar-mini{
        .visible-on-sidebar-regular{
          display: none !important;
        }
        .visible-on-sidebar-mini{
          display: inline-block !important;
        }

        .navbar-minimize{
          opacity: 0;
        }

        .sidebar,
        .sidebar .sidebar-wrapper{
          width: 80px;
        }

        .main-panel{
          width: $sidebar-mini-width;
        }

        .sidebar{
          display: block;
          z-index: 1030;
          box-shadow: $sidebar-box-shadow;

          .logo{
              a.logo-normal{
                  opacity: 0;
                  @include transform-translate-x(-25px);
              }
          }

          // .nav a i,
          // .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
          // .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon{
          //   margin-left: 0;
          //   width: 34px;
          // }

          // .user .photo{
          //   margin-left: 23px;
          // }

          .sidebar-wrapper{
            > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info > a > span,
            > .nav li > a p{
                @include transform-translate-x(-25px);
                opacity: 0;
            }
          }
        }

        .sidebar:hover {
          width: 260px;

          .logo {
            a.logo-normal {
                opacity: 1;
                @include transform-translate-x(0);
            }
          }


          .navbar-minimize{
            opacity: 1;
          }
          .sidebar-wrapper{
            width: 260px;

            > .nav li > a p,
            > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
            .user .info > a > span{
              @include transform-translate-x(0px);
              opacity: 1;
            }
          }
        }
  }
}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  position: relative;
  @include panel-gradient($black-color);

  .header{
    .title{
      color: $white-color;
    }
    .category{
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a{
        color: $white-color;
      }
    }
  }
}

.panel-header-sm{
  height: 135px;
}

.panel-header-lg{
  height: 380px
}
