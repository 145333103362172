.input-group-append,
.input-group-prepend {
  @extend .input-group-addon !optional;
}

.input-group-append:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  left: 1px;
}

.no-border > .input-group-append {
  border-width: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.panel-content {
  padding: 0 15px 30px;
}

.input-group-lg {
  .input-group-prepend {
    border-radius: 3px;
  }
  .form-control {
    border-radius: 3px;
  }
}
.form-control {
  background-color: $white;
}

.panel-header-flex {
  height: auto;
}
.panel-header-xs {
  height: 65px;
  padding-bottom: 0;
  padding-top: 0;
}
@media (max-width: 575px) {
  .panel-header-xs {
    height: 55px;
  }
}
.panel-header {
  background: $black-color;
}

.full-page .full-page-content {
  padding-bottom: 80px;
}

@media (max-width: 768px) {
  .full-page .full-page-content {
    padding-bottom: 125px;
  }
}

.full-page-content.shallow {
  padding-top: 80px;
}

small,
.small {
  font-size: 65%;
}

#minimizeSidebar {
  border-radius: 30px;
}

.dark {
  .form-check {
    .form-check-sign::before,
    .form-check-sign::after {
      background-color: $white-bg;
    }
  }
}

@media screen and (min-width: 992px) {
  .table-responsive {
    overflow: auto;
  }
}

@import 'overrides/alerts';
@import 'overrides/cards';
@import 'overrides/inputs';
@import 'overrides/navbar';
@import 'overrides/sidebar-and-main-panel';
@import 'overrides/dropdown';
