.combobox {
  position: relative;
  .input-group .form-control + .input-group-append {
    font-size: 0.8571em;
    padding: 0.4em 0.5em 0.3em 0.3em;
  }

  .combobox-items {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
    font-size: 0.8571em;
    background: $white-bg;
    z-index: 1;
    max-height: 10em;
    overflow-y: scroll;
    box-shadow: $dropdown-shadow;
    border: 1px solid #e3e3e3;
  }
  .combobox-item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e3e3e3;
    cursor: default;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: $blue;
      color: $white;
    }
    &.selected {
      font-weight: 700;
    }
    &.highlighted {
      background: $blue;
    }
  }
}
