.featured-form {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    margin-bottom: 0.2em;

    small {
      text-transform: none;
    }
  }

  h1 small {
    font-size: 1rem;
  }
}
