@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.team-member-card {
  padding: 15px 0;
  display: grid;
  grid-template-columns: 48px 2fr 1fr 60px;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar name allocation actions'
    'avatar role allocation actions'
    'avatar email allocation actions';
  grid-column-gap: 1em;

  @media only screen and (min-width: 1200px) {
    & {
      grid-template-columns: 48px repeat(2, minmax(40px, 1fr)) 40px 60px;
      grid-template-rows: auto auto;
      grid-template-areas:
        'avatar name role allocation actions'
        'avatar email role allocation actions';
      grid-column-gap: 1em;
    }

    .team-member-name {
      margin-bottom: -0.3rem;
    }

    .team-member-role {
      font-size: 1rem;
    }

    .team-member-role,
    .team-member-allocation,
    .team-member-actions {
      align-self: center;
    }
  }
}

.team-member-avatar {
  grid-area: avatar;
}

.team-member-avatar-image {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  color: bs.$white-color;
}

.team-member-name {
  grid-area: name;
  font-size: 1.2rem;
  // margin-bottom:-.3rem;
}
.team-member-role {
  grid-area: role;
  color: bs.$blue;
  font-size: 0.8rem;

  :global(.combobox) {
    width: 15em;
  }
}

.team-member-email {
  grid-area: email;
  font-size: 0.8rem;
  color: bs.$body-subdued-font-color;
}

.team-member-allocation {
  grid-area: allocation;
}

.team-member-actions {
  grid-area: actions;
  text-align: right;

  :global(.btn) {
    margin: 0.15rem;
  }

  :global(.fa-user-edit) {
    position: relative;
    left: 0.15em;
  }
}
