.alert {
  font-size: 1rem;
  padding-right: 2.5rem;

  button.close {
    top: auto;
    margin-top: 0;
    cursor: pointer;
  }

  i.fa {
    text-align: center;
    width: 1.25em;
    font-size: 18px;
    padding-top: 2px;
    margin-right: 0.75em;
    margin-top: 0.1em;
    padding: 0;
    opacity: 0.5;

    &.fa-warning:before {
      content: '\f071';
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
  }
}
