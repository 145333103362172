.live-assessment {
  &.question-set-history {
    .panel-content {
      padding-bottom: 0;
    }
  }

  .question-set-assessments {
    list-style: none;
    margin: 0;

    > li {
      margin: 0 0 1.5rem 0;
    }

    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0.5rem;
    }

    .question-overview-list {
      margin-left: -2.5px;
      margin-right: -2.5px;

      .question-overview-list-item {
        padding: 0 2.5px 5px;
      }
    }

    .question-title {
      display: none;
      font-size: 0.5rem;
      font-weight: normal;
      position: relative;
      z-index: 2;
      margin: 0;
    }

    @media #{$large-up} {
      .question-title {
        display: block;
      }
    }

    @media #{$xlarge-up} {
      .question-title {
        font-size: 0.8rem;
      }
    }

    .response-result,
    .no-results-chart {
      opacity: 0;
    }

    .response-data {
      transition: opacity 0.3s ease;
      opacity: 0;
      top: 5%;
      right: 5%;
      bottom: 5%;
      left: 5%;

      .bar-chart {
        width: 100%;
        height: 100%;
      }
    }

    .question-overview:hover .response-data {
      opacity: 1;
    }

    .response-count {
      display: none;
    }

    .question-overview {
      transition: background-color 0.5s ease;
    }
  }
}
