.page-result {
  margin-top: -4rem;

  .result-icon {
    font-size: 6rem;
    text-align: center;
    color: $blue;
  }

  .result-icon.error {
    color: $error-color;
  }
  .result-body {
    font-size: 1.5rem;

    form {
      margin-top: 3em;

      &:first-child {
        margin-top: 0;
      }
    }

    label,
    .invalid-feedback {
      font-size: 1rem;
    }
  }

  @media #{$medium-up} {
    margin-top: 0;
    display: flex;
    align-items: flex-start;

    .result-icon {
      position: static;
      margin: -0.18em 1rem 0 0;
      font-size: 12rem;
    }

    .result-body {
      h2 {
        margin-left: 0;
        min-height: auto;
      }

      flex: 1 1;
    }
  }
}
