@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.name {
  font-size: 1.6rem;
  padding: 15px 15px 0;
}

.body {
  padding: 0 15px 10px;
}

.no-activity {
  background-color: bs.$smoke-bg;
  border-radius: bs.$border-radius-small;
  padding: 1rem;
  color: #adb5bd;
  text-align: center;
  font-size: bs.$font-size-small;

  :global(.icon) {
    margin: 0.2rem 0 0.6rem;
  }
}

.workspace-summary {
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  .body {
    display: grid;
    grid-template-columns: 6rem auto;
    column-gap: 1rem;
  }

  .teams {
    font-size: 3rem;
    text-align: center;
    line-height: 1;

    .subtitle {
      font-size: 0.8rem;
    }
  }

  .history {
    min-height: 1.4 * 6em;

    label {
      margin-bottom: 0px;
    }

    .assessments {
      padding: 0;
      list-style: none;
    }
  }

  .actions {
    font-size: bs.$font-size-small;
    margin-top: 0.5rem;
    padding: 0;
    text-align: right;

    &.member {
      background-color: bs.$faded-green;
    }

    &.nonmember {
      background-color: bs.$faded-blue;
    }

    .content {
      padding: 0.5rem 15px;
    }
  }

  &:global(.skeleton) {
    .name:after {
      height: 1.4em;
    }

    .teams {
      .count {
        display: block;
        height: 3rem;
        margin: 0 auto;
      }

      .subtitle {
        display: block;
        height: 1.2em;
        margin: 0.4rem auto 0;
      }
    }

    .history {
      label:after {
        width: 6em;
        height: 1.4em;
      }

      .assessments {
        .item {
          height: 2em;
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}
