// prettier-ignore
.assessment-history-chart-glyph {
  .box-ul {
    fill: #375a28;
  }

  .box-ur {
    fill: #547247;
  }

  .box-ll {
    fill: #8ea286;
  }

  .box-lr {
    fill: #C8D2C4;
  }
}

// prettier-ignore
.assessment-history-stacked-bar-chart-glyph {
  .bar-1 {
    &.section-1 {
      fill: #8ea286;
    }

    &.section-2 {
      fill: #547247;
    }

    &.section-3 {
      fill: #375a28;
    }
  }

  .bar-2 {
    &.section-1 {
      fill: #C8D2C4;
    }

    &.section-2 {
      fill: #547247;
    }

    &.section-3 {
      fill: #375a28;
    }
  }

  .bar-3 {
    &.section-1 {
      fill: #547247;
    }

    &.section-2 {
      fill: #375a28;
    }
  }
}

.assessment-history-box-plot-glyph {
  .min-to-first,
  .max-to-third {
    fill: #aaa;
  }

  .min,
  .max,
  .box {
    stroke: #aaa;
    stroke-width: 2px;
    fill: transparent;
  }

  .median {
    fill: $yellow;
  }

  .visx-violin {
    stroke: rgba($blue, 0.4);
  }

  .glyph-violin-lines {
    stroke: rgba($blue, 0.25);
  }
}

.assessment-history-radar-chart-glyph {
  .radar-back {
    path,
    line {
      stroke: #aaa;
      fill: none;
    }

    line {
      stroke-dasharray: 1 2;
    }
  }

  .scores {
    circle {
      stroke: none;
      fill: $blue;
    }

    .score-fill {
      stroke: none;
      fill: rgba($blue, 0.85);
    }
  }
}

.assessment-history-chart {
  .assessment-history-questions {
    > ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .assessment-history-question-set {
    flex-grow: 0;
    flex-shrink: 0;
    list-style: none;
    margin: 0 30px 0 0;
    max-width: 100%;

    .question-set-name {
      font-size: 0.7rem;
      width: 100%;

      a {
        color: $white;
        text-decoration: none;
      }
    }

    &.stacked-bar,
    &.box-plot {
      > ul li:first-of-type {
        position: relative;
        margin-left: 8px;

        &:before {
          content: ' ';
          position: absolute;
          left: -8px;
          width: 2px;
          height: 120px;
          background: linear-gradient(0deg, #e5eae3 0%, #375a28 100%);
          display: block;
        }
      }
    }
  }

  .assessment-history-question {
    height: 20px;
    width: 20px;
    margin: 0 6px 0px 0;
    list-style: none;
    line-height: 0;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      height: 100%;
      width: 100%;
    }

    &.unscored,
    &.not-scorable {
      position: relative;

      .assessment-history-unscored-background {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        opacity: 0.5;
        pointer-events: none;
      }

      &:not(:has(a)) {
        position: relative;
        display: table;

        &:hover {
          text-decoration: none;
        }

        &:before {
          color: $no-response-marker-color;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          font-size: 9px;
        }
      }

      a {
        display: table;

        &:hover {
          text-decoration: none;
        }

        &:before {
          color: $no-response-marker-color;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          font-size: 9px;
        }
      }
    }

    &.unscored {
      // border: 1px solid rgba($blue, 0.5);
      border: 1px solid $white-color;

      &:not(:has(a)):before {
        color: $white-color;
        content: 'U';
      }

      a:before {
        color: $white-color;
        content: 'U';
      }
    }

    &.not-scorable {
      border: 1px solid rgba($blue, 0.5);

      &:not(:has(a)):before {
        content: 'NS';
      }

      a:before {
        content: 'NS';
      }
    }
  }

  .assessment-history-question-box-plot,
  .assessment-history-question-stacked-bar {
    display: inline-block;
    width: 20px;
    height: 120px;
    margin: 0 6px 14px 0;

    &.unscored {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.5;
        border: 1px solid rgba($white-color, 0.5);
        pointer-events: none;
        content: '';
      }

      &:after {
        content: 'U';
        position: absolute;
        color: rgba($white-color, 0.5);
        bottom: 0em;
        right: 0.3em;
        font-size: 9px;
        // width: 2em;
        // height: 2em;
        // border: 1px solid rgba($white-color, 0.5);
        // background-color: $black-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
      }

      svg {
        opacity: 0.5;
      }
    }

    &.no-responses {
      text-align: center;
      background: rgba($blue, 0.5);
    }

    &.not-scorable {
      a {
        width: 100%;
        height: 100%;
        border: 1px solid rgba($blue, 0.5);
        display: table;

        &:hover {
          text-decoration: none;
        }

        &:before {
          color: $no-response-marker-color;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          font-size: 9px;
          content: 'NS';
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .visx-boxplot-max,
    .visx-boxplot-min {
      stroke: rgba($white, 0.6);
      stroke-width: 2;
    }

    .visx-boxplot-max-to-third,
    .visx-boxplot-box,
    .visx-boxplot-min-to-first,
    .visx-boxplot-median {
      stroke: rgba($white, 0.6);
      stroke-width: 1px;
      fill: transparent;
    }

    .visx-boxplot-median {
      stroke: $yellow;
    }

    .visx-violin {
      stroke: rgba($blue, 0.4);
    }

    .visx-pattern-line {
      stroke: rgba($blue, 0.25);
    }
  }

  .no-response-marker {
    fill: rgba($no-response-marker-color, 0.5);
  }

  .no-response-bg {
    fill: transparent;
  }

  .assessment-history-circle-chart {
    .bounding-circle {
      stroke: $chart-stroke-color;
      fill: none;
    }

    .wedge-divider {
      stroke: $chart-stroke-color;

      &.inner-divider {
        stroke-width: 0.5;
      }
    }

    .chart-wedge-hover-spacer {
      fill: transparent;
    }

    .circle-chart-wedge-hover-target:hover {
      .chart-wedge-question-set {
        filter: saturate(85%) brightness(200%);
      }
    }

    .chart-grid-dense {
      stroke: $chart-stroke-color;
      fill: none;
    }

    .chart-wedge-question-set {
      filter: saturate(70%) brightness(140%);

      &.unanswered {
        fill: rgba($no-response-marker-color, 0.1);
      }
    }
  }

  .assessment-history-radar-figure {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'stats'
      'circle';

    .figure-stats {
      padding-bottom: 1em;
      grid-area: stats;

      .view-name {
        color: $info-color;
      }
    }

    .figure-radar {
      grid-area: circle;
    }

    .figure-spacer {
      display: none;
    }

    .figure-detail {
      display: none;
    }
  }

  @media #{$medium-up} {
    .assessment-history-radar-figure {
      margin-top: 1em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content 1fr max-content;
      grid-template-areas:
        'radar stats'
        'radar spacer'
        'radar detail';
      column-gap: 2em;

      .figure-radar {
        grid-area: radar;
        grid-column-start: 1;
        grid-column-end: 2;
      }

      .figure-stats {
        grid-area: stats;
        padding-bottom: 2.5em;

        .view-name {
          color: $info-color;
        }

        .high-variance {
          color: $danger-color;
        }

        .medium-variance {
          color: $warning-color;
        }

        .na-variance {
          color: $body-subdued-font-color;
        }
      }

      .figure-spacer {
        display: block;
        grid-area: spacer;
      }

      .figure-detail {
        display: block;
        grid-area: detail;

        label {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .set-details-chart .detail-points {
    .detail-point {
      filter: saturate(60%) brightness(160%);
    }

    circle {
      mask-position: center;
    }

    &.unanswered {
      fill: rgba($no-response-marker-color, 0.5);
    }
  }
}
