@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.root {
  color: white;
}

.content {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.itemText {
  width: 250px;
  padding-left: 20px;
}

.root {
  a {
    color: #ffffff;
  }

  a:hover {
    color: #ffffff;
  }
}
