@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.answer-grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
  font-size: 0.85rem;
}

.score {
  grid-column: 1;

  .score-value {
    position: relative;
    top: 0.1rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 0.5rem;
    border: 4px solid transparent;
  }
}

.panel-section {
  margin-bottom: 1rem;
}

.overview-chart {
  max-width: 225px;
}

.overview-title {
  grid-column-start: 1;
  grid-column-end: -1;

  h3 {
    color: bs.$blue;
    font-size: 1rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.overview-stats {
  display: grid;
  max-width: 275px;
  grid-template-columns: 1fr 1fr;
}
