.data-filter {
  font-size: 0.8rem;

  .dropdown-menu .dropdown-item {
    margin-top: 0;
  }

  .btn-sm {
    font-size: 0.8rem;
  }
}
