.TeamInfo {
  :global(.alert) {
    display: block;
    div {
      max-width: unset;
    }
  }
}

.ArchiveState {
  font-weight: bold;
}
