@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.answer-sets {
  margin-top: 5px;
  display: grid;
  grid-template-columns: min-content 1fr;
  font-size: 1rem;

  thead {
    font-size: 0.8rem;
  }
}

.answer-set-info {
  // display: flex;
}

.answer-set-usage {
  padding: 4px 0;
}

.answer-set-usage,
.answer-set-info {
  border-top: 1px solid bs.$hr-card;
  position: relative;

  &:nth-child(n + 1):nth-child(-n + 2) {
    border-top: none;
  }
}

.answer-set-badge {
  padding: 0 3px;
  line-height: 20px;
  font-size: 0.6rem;
  color: white;
  background-color: bs.$blue;
  border-radius: 10px;
  min-width: 20px;
  display: inline-block;
  text-align: center;
}

.answer-set-card-header {
  display: grid;
  grid-template:
    'name name actions'
    'spark type actions';
  grid-template-columns: 1fr min-content;
  width: 100%;
}
.answer-set-card-body {
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
}
.answer-set-name {
  padding: 6px 10px;
  grid-area: name;
}
.answer-set-spark {
  // align-content: end;
  padding: 0 10px;
  margin-top: -16px;
  // margin-bottom: 3px;
  grid-area: spark;
}
.answer-set-type {
  font-size: 0.7rem;
  margin-top: -16px;
  line-height: 16px;
  // margin-bottom: 3px;
  // margin-right: 10em;
  grid-area: type;
  padding-right: 1em;
  color: bs.$blue;
  white-space: nowrap;

  // &.unscored {
  // }

  &.scored {
    color: bs.$green;
  }
}

.answer-set-actions {
  white-space: nowrap;
  padding: 6px 10px;
  grid-area: actions;
  padding: 0 0 0 1px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: bs.$smoke-bg;
  }

  :global(.btn-inline-action):not(:first-child) {
    margin-left: 0;
  }

  &.is-open button {
    background-color: bs.$smoke-bg;

    &:hover,
    &:active,
    &:focus {
      background-color: #868e96;
    }
  }

  button {
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
    height: 100%;
    // background-color: bs.$smoke-bg;
    border-radius: 0;

    svg {
      transition: transform 200ms;
    }
  }
}

.answer-set-bottom-actions {
  padding: 0;

  :global(.btn) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}

.drag-column,
.value-column {
  width: 1px;
}

.score-cell {
  position: relative;

  &.score-bar-only {
    padding: 0;
  }
}
.score-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
}
.score-na {
  background-color: #e0eff3;

  .score-bar {
    background-color: bs.$blue;
  }
}

.answer-set-standard-message {
  padding: 10px;
  // background-color: #e0eff3;
  background-color: bs.$smoke-bg;
  color: bs.$dark-gray;
  font-size: 0.8rem;
  // grid-column-start: 1;
  // grid-column-end: -1;
}
.answer-set-standard-message-pad {
  // padding: 10px;
  background-color: bs.$smoke-bg;
}

.answer-set-usage-pad {
  background-color: bs.$smoke-bg;
}
.answer-set-settings {
  padding: 10px;
  background-color: bs.$smoke-bg;
}
.choices-controls {
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
}
.answer-set-choices {
  table {
    width: 100%;
  }
  table,
  tr {
    border: 1px solid bs.$light-gray;
    border-collapse: collapse;
  }
  thead {
    color: bs.$dark-gray;
  }
  tbody {
    background-color: bs.$white-color;
  }

  th {
    font-weight: normal;

    &.shifted {
      padding-left: 9px;
      padding-right: 10px;
    }
  }

  .input-padding {
    display: inline-block;
    padding: 3px 4px;
  }

  .input-padding-score {
    display: inline-block;
    padding: 3px 4px 3px 7px;
  }

  td {
    padding: 5px;
  }

  .answer-text {
    max-width: 100%;
    width: 100%;
  }
  .answer-score {
    max-width: 30px;
    margin-left: 3px;
  }
  .answer-actions {
    max-width: 22px;
    font-size: 0.8rem;
  }

  input {
    border-color: transparent;
    background-color: transparent;
    border-radius: bs.$border-radius-small;
  }
  input:focus,
  input:focus-within,
  input:focus-visible {
    background-color: bs.$white-color;
    box-shadow: 0 0 0 1px bs.$primary-color;
    outline: 0;
  }
}

.choice {
  position: relative;
}

.drop-indicator {
  z-index: 2;
  left: 0;
  height: 4px;
  position: absolute;
  margin-left: 8px;
  width: calc(100% - 8px);
  border-radius: 0 2px 2px 0;

  &.top {
    top: -3px;
    bottom: unset;
  }
  &.bottom {
    top: unset;
    bottom: -3px;
  }
}

.drop-indicator::before {
  content: '';
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid bs.$blue;
  position: absolute;
  left: -8px;
  top: -3.5px;
}
