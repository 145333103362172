.switch-menu {
  padding: 0;
  margin: 0 -0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;

  .switch-menu-item {
    list-style: none;
    padding: 0.25em;
    margin: 0.5em;
    border: 2px solid rgba($blue, 0.3);
    border-radius: $border-radius;
    background: transparent;

    &:focus {
      outline: 0;
      border: 2px solid rgba($blue, 0.5);
    }

    &.active {
      border: 2px solid $blue;
      background-color: transparent;
      outline: 0;
    }
  }
}
