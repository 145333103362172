.has-ballot-marker {
  padding-bottom: 6rem;
}
.ballot-marker {
  font-size: 1rem;

  button,
  .button {
    margin-bottom: 0;
    white-space: nowrap;
    border: 0px solid transparent;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    line-height: 2em;
    cursor: pointer;

    &:not([data-action]):hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .fa-layers {
      height: 0;
      position: relative;
      top: -0.3em;
    }
  }
}

@media (max-width: 375px) {
  .ballot-marker {
    button,
    .button {
      font-size: 85%;
    }
  }
}
