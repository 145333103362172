.action-selector {
  &.show > .btn.dropdown-toggle,
  &.show > .btn.btn[aria-haspopup='true'] {
    background-color: $blue;
    border: 1px solid $blue;
    color: $body-action-font-color;
    color: $white-color;

    &::after {
      border-top: 0.3em solid $white-color;
    }
  }

  .dropdown-toggle {
    border: 1px solid $body-action-font-color;
    color: $body-action-font-color;
    background-color: $white-color;
    padding: 0.25rem 0.5rem;
    width: 10em;
    text-align: left;
    display: grid;
    grid-template-columns: max-content min-content;
    justify-content: space-between;
    align-items: center;

    &::after {
      border-top: 0.3em solid $blue;
    }

    &:hover {
      color: $white-color;
    }
  }

  .dropdown-item {
    padding: 0.3rem 0.5rem;

    &.active {
      background-color: $faded-blue;
    }
  }
}
