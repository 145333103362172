.CollaboratorList {
  .Initials {
    display: inline-block;

    &:not(:last-child) {
      margin-right: -4px;
    }
  }
}

.Initials {
  box-shadow: 0 0 2px rgba(0, 0, 0, 1);
  border-radius: 20px;
  padding: 6px 3px;
  text-align: center;
  font-size: 10px;
  cursor: default;
  width: 25px;
  line-height: normal;
}

.Initials.small {
  font-size: 10px;
  padding: 4px 0;
  font-size: 9px;
  width: 22px;
}

.tip {
  font-size: 0.8em;
}

.focusContainer {
  position: relative;
  width: fit-content;

  &.otherEditing {
    border-radius: 5px;
    padding: 3px 5px;
    border-width: 2px;
    border-style: solid;
  }
}

.collaborator {
  position: absolute;
  top: -12px;
  left: -3px;
}
