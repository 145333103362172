#verify-account-page {
  color: $white;

  .full-page-background {
    background: url(/images/cartographer.png) -35px -200px repeat;
    z-index: 2;
  }

  .loading {
    font-size: 12rem;
    text-align: center;
    color: $blue;
  }

  .page-error {
    .result-icon {
      transform: scale(-1, 1);
    }
  }
}
