.card{
    border: 0;
    border-radius: $border-radius-small;
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: $box-shadow;

    .card-body{
        padding: 15px 15px 10px 15px;

        &.equal-padding {
            padding: 15px;
        }

        &.table-full-width{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;



        .card-title{
            margin-top: 10px;
        }
    }

    &.card-wizard{
      .card-header{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 40px;
      }
    }

    .map{
        height: 280px;
        border-radius: 3px;

        &.map-big{
          height: 400px;
        }
    }

    .statistics-container{
        margin-left: 38px;
        position: relative;
        top: 24px;

        .statistics,
        ul,
        .badge{
            display: inline-block;
        }

        .statistics-badge{
            float: right;
            position: relative;
            top: 31px;

            .badge{
                font-size: 14px;
                line-height: 1.1;
            }
        }

        ul{
            list-style: none;
            position: relative;
            top: -35px;
            left: 7%;

            li{
                font-size: $font-size-mini;
                color: $dark-gray;
                margin-bottom: 7px;

                .stats-value{
                    float: right;
                    width: 42px;
                    position: relative;
                    right: -12px;
                    color: initial;
                }
            }
        }

        p{
            margin: 0;
        }

        h3{
            margin-bottom: 15px;
            color: $primary-color;
        }
    }

    &[data-background-color="orange"]{
        background-color: $primary-color;

        .card-header{
            background-color: $primary-color;
        }

        .card-footer{
            .stats{
                color: $white-color;
            }
        }
    }

    &[data-background-color="red"]{
        background-color: $danger-color;
    }

    &[data-background-color="yellow"]{
        background-color: $warning-color;
    }

    &[data-background-color="blue"]{
        background-color: $info-color;
    }

    &[data-background-color="green"]{
        background-color: $success-color;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    .info-area{
        padding: 40px 0 40px;
        text-align: center;
        position: relative;
        z-index: 2;

    }

    label{
        font-size: $font-size-small;
        margin-bottom: 5px;
        color: $dark-gray;
    }

    .ct-chart{
        margin: 30px 0 30px;
    }

    .card-footer{
        background-color: transparent;
        border: 0;


        .stats{
            i{
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
        }

        .btn{
            margin: 0;
        }
    }
}

.card-collapse{
    .card{
        margin-bottom: 25px;

        .card-header{
            position: relative;
            padding: .75rem 1.25rem;
            padding-left: 0;
            padding-right: 0;

            a[data-toggle="collapse"]{
                display: block;
                color: $light-black;

                i{
                    float: right;
                    position: relative;
                    color: #f96332;
                    top: 1px;
                    @extend .animation-transition-general;
                }
            }

            &:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $light-gray;
            }
        }

        .card-body{
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}

.card-subcategories .card-body{
    padding-bottom: 30px;
}

.card-pricing{
    text-align: center;

    .card-category{
        padding-top: 1.25em;
    }

    .card-title{
        margin-top: 30px;
    }

    .card-icon{
        padding: 10px 0 0px;

        i{
            font-size: 30px;
            line-height: 2.7;
            max-width: 80px;
            color: $default-color;
            width: 80px;
            height: 80px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.3);
            background-color: $white-color;
            position: relative;
        }

        &.icon-primary i{
            @include icon-color($primary-color);
        }
        &.icon-info i{
            @include icon-color($info-color);
        }
        &.icon-success i{
            color: $success-color;
            box-shadow:  0px 9px 30px -6px rgba(22, 199, 13, 0.85);
        }
        &.icon-warning i{
            @include icon-color($warning-color);
        }
        &.icon-danger i{
            @include icon-color($danger-color);
        }
    }
    h1{
        small{
            font-size: 18px;

            &:first-child{
                position: relative;
                top: -17px;
                font-size: 26px;
            }
        }
    }

    ul{
        list-style: none;
        padding: 0;
        max-width: 240px;
        margin: 10px auto;

        li{
            color: $default-color;
            text-align: center;
            padding: 12px 0;
            border-bottom: 1px solid rgba($default-color,.3);

            &:last-child{
                border: 0;
            }
            b{
                color: $black-color;
            }
            i{
                top: 3px;
                right: 3px;
                position: relative;
                font-size: 20px;
            }
        }
    }

    &.card-background{
        ul{
            li{
                color: $white-color;
                border-color: rgba($white-color,.3);

                b{
                    color: $white-color;
                }
            }
        }
        [class*="text-"]{
            color: $white-color !important;
        }
        .card-body{
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
    }
    &.card-background:after{
        background-color: rgba(0, 0, 0, 0.65);
    }
}

.card-user{
    .image{
        height: 120px;
    }

    .author{
        text-align: center;
        text-transform: none;
        margin-top: -77px;

        a +  p.description{
            margin-top: -7px;
        }
    }

    .avatar{
        width: 124px;
        height: 124px;
        border: 1px solid $white-color;
        position: relative;
    }

    .card-body{
        min-height: 240px;
    }

    hr{
        margin: 5px 15px;
    }

    .button-container{
        margin-bottom: 6px;
        text-align: center;
    }
}

.card-profile,
.card-testimonial{
    margin-top: 30px;
    text-align: center;

    .card-body .card-title{
        margin-top: 0;
    }

     [class*=col-]{
        .card-description{
            margin-bottom: 0;

            & + .card-footer{
                margin-top: 8px;
            }
        }


     }

    .card-avatar{
        max-width: 130px;
        max-height: 130px;
        margin: -60px auto 0;

        img{
            border-radius: 50% !important;
        }

        & + .card-body{
            margin-top: 15px;
        }
    }

    &.card-plain{
        .card-avatar{
            margin-top: 0;
        }
    }
    .card-body{
        .card-avatar{
            margin: 0 auto 30px;
        }
    }
}

.card-testimonial .card-description + .card-title{
    margin-top: 20px;
}

.card-testimonial{
    .card-body{
        padding-top: 25px;
    }

    .card-avatar{
        max-width: 100px;
        max-height: 100px;
        margin-top: -50px;
    }
    .card-footer{
        margin-top: 0;
        margin-bottom: 2.5rem;
    }
    .card-description{
        & + .card-title{
            margin-top: 30px;
        }
    }
    .icon{
        i{
            font-size: 32px;
        }

        &.icon-primary i{
            color: $primary-color;
        }

        &.icon-info i{
            color: $info-color;
        }

        &.icon-danger i{
            color: $danger-color;
        }

        &.icon-warning i{
            color: $warning-color;
        }

        &.icon-success i{
            color: $success-color;
        }
    }
}

.card-lock{
    .card-header{
        img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-top: -60px;
            box-shadow: $box-shadow;
        }
    }

}


.card-signup{
    .header{
        margin-left: 20px;
        margin-right: 20px;
        padding: 30px 0;
    }
    .text-divider{
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
    }
    .content{
        padding: 0px 30px;
    }

    .form-check{
        margin-top: 20px;

        label{
            margin-left: 14px;
            padding-left: 38px;
        }
    }

    .social-line{
        margin-top: 20px;
        text-align: center;

        .btn.btn-icon ,
        .btn.btn-icon .btn-icon{
            margin-left: 5px;
            margin-right: 5px;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
        }
    }

    .card-footer{
        margin-bottom: 10px;
        margin-top: 24px;
    }
}

.card-plain{
    background: transparent;
    box-shadow: none;

    .card-header,
    .card-footer{
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }

    &:not(.card-subcategories).card-body{
        padding-left: 0;
        padding-right: 0;
    }
}

.card-stats,
.card-contributions{
  hr{
    margin: 5px 15px;
  }
}

.card-stats{
    .statistics{
        position: relative;
        text-align: center;
        padding: 15px 0;

        .title{
            margin-bottom: 0;
        }
        .stats-title{
            margin-bottom: 5px;
            color: $dark-gray;
            font-weight: $font-weight-normal;
        }

        &.statistics-horizontal,
        &.statistics-horizontal .info-title{
          padding: 0;
        }
    }

    .icon{
      display: inline-block;
      vertical-align: top;
      margin: 0 15px;
    }

    .stats-information{
      display: inline-block;
      margin-bottom: 15px;

      .stats-text{
        font-size: 29px;
      }

      .stats-details{
        display:block;
        color: $default-color;
      }
    }

    .dots{
      text-align: right;

      .dot{
        background-color: $black-color;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    [class*="col-"]{
        .statistics::after{
            position: absolute;
            right: 0;
            top: 20px;
            width: 1px;
            height: calc(100% - 40px);
            content: "";
            background: $medium-gray;

        }
    }
    [class*="col-"]:last-child{
        .statistics::after{
            display: none;
        }
    }
}
.card-contributions{
  .card-description{
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .card-title{
    padding-top: 35px;
  }

  .card-stats{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 11px;
  }

  .card-footer{
    [class*="col-"]:not(:first-child):before{
      content: "";
      position: absolute;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: $hr-line;
    }
  }

  .bootstrap-switch{
    margin: 0;
  }

  span{
    padding-left: 15px;
    text-align: left;
    max-width: 125px;
  }

}
.card-background{
    background-position: center center;
    background-size: cover;
    text-align: center;

    .card-body{
        position: relative;
        z-index: 2;
        min-height: 370px;
        max-width: 530px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;;
    }
    .card-footer{
      position: relative;
      z-index: 2;
    }

    &.card-background-product .card-body{
        max-width: 400px;
        .card-title{
            margin-top: 30px;
        }
    }

    .stats{
        color: $white-color;
    }

    .card-footer{
        .stats-link > a{
            color: $white-color;
            line-height: 1.9;
        }
    }

    .category,
    .card-description,
    small{
        color: $opacity-8;
    }

    .card-title {
        color: $white-color;
        margin-top: 130px;
    }

    &:not(.card-pricing) .btn {
        margin-bottom: 0;
    }

    &::after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.63);
        border-radius: $border-radius-large;
    }
}

.card-stats-mini {
  &.card-background::after {
    background-image: linear-gradient(to right, #434343 0%, black 100%);
    opacity: .94;
  }

  .card-body {
    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }

  .card-footer {
    border-top: 1px solid $opacity-2;
    margin: 0 15px;
  }

  &.card-background {
    .card-body {
      min-height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .card-title {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .info-area {
    text-align: left;
    width: 40%;
    float: left;
    padding: 15px 0;

    .icon > i {
      font-size: 2em;
    }
  }

  .chart-area {
    float: left;
    width: 60%;
  }

  .stats {
    text-align: left;
    color: $white-color;
  }
}

.card-chart {
  .card-header{
    .card-title{
      margin-top: 10px;
      margin-bottom: 0;
    }
    .card-category{
      margin-bottom: 5px;
    }
  }

  .table{
    margin-bottom: 0;

    td{
      border-top: none;
      border-bottom: 1px solid #e9ecef;
    }
  }

  .card-progress {
    margin-top: 30px;
  }

  .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
  .card-footer {
    margin-top: 15px;

    .stats{
      color: $dark-gray;
    }
  }

  .dropdown{
    position: absolute;
    right: 20px;
    top: 20px;

    .btn{
      margin: 0;
    }
  }
}
