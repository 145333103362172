#home-page {
  margin-bottom: 7rem;

  .full-page-background {
    background: url(/images/cartographer.png) -35px -200px repeat;
    z-index: 2;
  }

  .assessment-start {
    position: relative;
    z-index: 3;
    color: #fff;

    .join-call {
      margin-bottom: -0.5rem;
    }

    input,
    button,
    .button {
      padding-top: 1rem;
      padding-bottom: 1.0625rem;
      font-size: 1.667rem;
      height: auto;
    }

    form {
      position: relative;
    }

    i {
      margin-right: 0.3em;
    }

    .validation-message {
      display: block;
      margin-top: -1.5rem;
    }

    .join-message {
      margin-bottom: 1.3rem;
      p {
        margin-bottom: 0;
      }
    }
    .join-form {
      max-width: $medium-breakpoint;
      font-size: 1rem;

      input {
        background-color: #fff;
      }

      .btn {
        width: 100%;
      }
    }

    .relative-wrapper {
      position: relative;
    }
    .loading-passphrase {
      position: absolute;
      color: $action-color;
      right: 0.25em;
      padding: 0.2rem;
      top: 50%;
      margin: -0.5em 0 0;
      font-size: 2.667rem;
    }
  }
}
