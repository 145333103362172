.stamp {
  color: #b4b4b4;
  cursor: pointer;
  display: inline-block;

  svg {
    transition: color 0.3s, transform 0.7s, filter 0.7s;
    transform: rotate(-23deg);
  }

  .background-layer {
    color: #fff;
  }

  &:hover {
    color: #7b79e0;

    svg {
      transform: rotate(23deg);
    }
    .background-layer {
      color: #f8fc0a;
      filter: drop-shadow(0 0 5px #fcd80a);
    }
  }
}
