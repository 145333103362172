.navbar {
  p {
    margin: 0 0 0 0.3em;
  }
  .container-fluid {
    padding: 0;
  }
  .navbar-brand {
    text-transform: none;
    font-size: 1.3rem;
    .logo {
      height: 1.9rem;
      margin-right: 0.15em;
      position: relative;
      top: -0.225rem;
    }
  }

  .navbar-nav {
    text-transform: none;

    .nav-link {
      text-transform: none;

      &.btn {
        padding: 0.5rem 0.7rem;
        margin: 0;
        color: $white;

        &:hover {
          background-color: rgba($white, 0.2);
        }
      }
    }

    .btn.nav-link {
      background-color: transparent;

      &[aria-expanded='true'] {
        background-color: rgba($white, 0.2);
      }
    }
  }

  &.bg-white {
    &.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
      color: $black-color;

      &:hover {
        color: $black-color;
      }
    }

    .navbar-nav {
      .nav-link.btn {
        color: $black-color;
        width: 100%;
        text-align: left;

        &:hover {
          box-shadow: none;
          background-color: $opacity-gray-8;
        }
      }
    }
  }
}
