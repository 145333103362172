.survey-review-chart {
  .chart-title {
    font-size: 0.8rem;
    margin: 0;
    color: $body-subdued-font-color;
  }

  .review-chart-axis {
    stroke: $chart-stroke-color;
    stroke-width: 1px;
  }

  .question-set-bar {
    stroke: $chart-stroke-color;
    stroke-width: 1px;
  }

  .question-score {
    &:hover {
      .response {
        fill: rgba($blue, 0.1);
      }
      .no-response {
        fill: rgba($yellow, 0.1);
      }
    }

    .response {
      fill: transparent;
    }

    .no-response {
      fill: transparent;
    }

    .no-response-marker {
      fill: rgba($no-response-marker-color, 0.5);
    }

    .no-response-bg {
      fill: transparent;
    }
  }
}
