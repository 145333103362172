@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.pricingFeatures {
  list-style: none;
  padding-left: 0;
  margin-left: 0;

  li {
    margin-left: 0;
    padding-left: 2.5em;
    padding-right: 0.5em;
  }

  svg {
    position: relative;
    left: -1.7em;
    color: bs.$green;
    margin-right: -1em;
  }
}
