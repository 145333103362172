.main-panel div.live-assessment > .content {
  padding-top: 65px;
  background-color: #fff;
  min-height: 100vh;
  margin-top: -55px;
  padding-bottom: 5.5em;
}

.main-panel div.live-assessment {
  &.vote-red > .content {
    // background-color: $poor-background;
  }
  &.vote-yellow > .content {
    // background-color: $meh-background;
  }
  &.vote-green > .content {
    // background-color: $good-background;
  }
}

.main-panel div.not-available-msg {
  display: flex;
  align-items: center;
  margin: auto;

  & p {
    margin: 0;
  }
}

@media #{$large-up} {
  .main-panel div.live-assessment > .content {
    padding-top: 80px;
    margin-top: -65px;
  }
}

// @media (max-width: 575px) {
//   .main-panel div.live-assessment > .content {
//     margin-top: -55px;
//   }
// }

.live-assessment {
  .improving {
    color: $green;
    fill: $green;
  }
  .deteriorating {
    color: $red;
    fill: $red;
  }

  .content.has-bottom-bar {
    padding-bottom: 85px;
  }

  .sidebar-item {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    h4 {
      margin-top: 0;
      padding-top: 0.3rem;
      font-size: 1.3rem;
    }
  }

  .participant-list {
    font-size: 0.8rem;
  }

  .overview {
    display: grid;
    grid-template-columns: 1fr minmax(220px, auto);
    gap: 30px;

    .participants .participant {
      font-size: 0.8rem;
    }
  }

  .question-sets .questions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 20px;
    justify-content: start;
  }

  .questions .question {
    min-width: 150px;
    max-width: 230px;
  }

  @media screen and (max-width: 676px) {
    .overview {
      grid-template-columns: 1fr;
    }

    .overview .participants {
      grid-row-start: 1;
    }

    .question-sets .questions {
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
  }
}
