.panel-header {
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    color: $white;
    margin-bottom: 0.2em;
  }

  h1 {
    font-size: 2.75rem;
  }
  h2 {
    font-size: 2rem;
  }

  label {
    font-size: 0.85rem;
    color: $body-subdued-font-color;
    margin-bottom: -0.8em;
  }

  .tab-nav {
    margin-top: 1em;
  }

  .nav.nav-underline {
    .nav-item {
      font-size: 0.9rem;

      a,
      a:visited {
        color: $white;
      }

      a:hover {
        opacity: 0.8;
        border-bottom: 2px solid $blue;
      }

      a.active {
        opacity: 1;
        border-bottom: 2px solid $blue;
      }

      a {
        transition: opacity 200ms, border 200ms;
        border-bottom: 2px solid $blue;
        opacity: 0.4;
        margin: 0 10px;
        padding: 0 1em;
      }
    }
  }

  .panel-body {
    margin-top: -30px;
  }
  .panel-actions {
    text-align: right;
    margin-top: 1rem;
  }
  .panel-body-no-label {
    margin-top: -25px;
    .panel-actions {
      margin-top: -0.5rem;
    }
  }
}
