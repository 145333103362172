@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.add {
  background-color: bs.$add-item-dark-bg;
  display: grid;
  width: 100%;
  min-height: 8em;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 0;
  position: relative;
}
