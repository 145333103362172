.live-assessment {
  $inverse-red: adjust-color($red, $lightness: 50%, $saturation: -15%);
  $inverse-yellow: adjust-color($yellow, $lightness: -30%, $saturation: 15%);
  $inverse-green: adjust-color($green, $lightness: 50%, $saturation: -15%);

  .question-title {
    padding-top: 0.3em;
    font-size: 1.6875rem;
  }

  .question-responses {
    position: relative;
    list-style: none;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 0.8rem;

    // > li {
    //   margin-bottom: 1em;

    //   &:last-of-type {
    //     margin-bottom: 0;
    //   }
    // }
  }

  .question-response {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 1rem 0.8rem;
    align-items: center;
    position: relative;
    text-align: left;
    border: none;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: $border-radius;
    padding: calc(0.5rem - 1px);
    margin: calc(-0.5rem - 1px);
  }

  button.question-response {
    color: $body-color;

    &:focus {
      border: 2px solid $orange;
    }

    &:hover {
      border: 2px solid $blue;
      border-radius: $border-radius;
      background-color: rgba($blue, 0.2);
      cursor: pointer;
    }
  }

  .response-icon {
    font-size: 275%;
    align-self: start;
    transition: 0.2s;
  }

  @media #{$small-only} {
    h2.question-title {
      padding: 0.4375rem 0;
    }
  }

  //$inverse-red: adjust-color($red, $lightness:50%, $saturation:-15%);
  //$inverse-yellow: adjust-color($yellow, $lightness:-30%, $saturation:15%);
  //$inverse-green: adjust-color($green, $lightness:50%, $saturation:-15%);

  .question-response.not-chosen:hover .response-icon {
    transform: none;
  }

  .question-response.not-chosen.performing-poorly:hover .response-icon,
  .performing-poorly .response-icon {
    color: $red;
    .fa-inverse {
      color: $inverse-red;
    }
  }

  .question-response.not-chosen.performing-middle:hover .response-icon,
  .performing-middle .response-icon {
    color: $yellow;
    .fa-inverse {
      color: $inverse-yellow;
    }
  }

  .question-response.not-chosen.performing-well:hover .response-icon,
  .performing-well .response-icon {
    color: $green;
    .fa-inverse {
      color: $inverse-green;
    }
  }

  $desat-icon: #ddd;
  $desat-inverse: #666;
  .chosen .response-icon {
    transform: scale(1.1);
  }
  .not-chosen {
    color: #777;

    .response-icon {
      transform: scale(0.8);
      color: $desat-icon;
      .fa-inverse {
        color: $desat-inverse;
      }
    }
  }
}
