.content-editable {
  position: relative;

  .content-wrapper {
    display: inline-block;
  }

  &.editing {
    margin: -1px;

    .content-wrapper {
      padding: 0.3em;
      margin: -0.3em;
      border-radius: 0.25rem;
      border: 1px solid $primary-color;
    }
  }
}

[contenteditable] {
  position: relative;
  // display: inline;
  border-right: 0.3em solid transparent;

  &,
  &:focus {
    outline: 0px solid transparent;
    // border-bottom: .3em solid transparent;
  }

  &.editable {
    display: inline-block;
  }
}

// .content-editable {
//   display: inline-block;
//   margin-bottom: -.3em;
// }

.content-editable-actions {
  display: inline-block;

  button:first-child {
    margin-left: 0;
  }
}
