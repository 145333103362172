@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.assessment-activity-item {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 0.3rem;

  .icon {
    font-size: 2rem;
  }

  .details {
    padding-top: 0.3rem;
  }

  .subdetails {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto;
    column-gap: 1rem;
  }

  .assessment-name {
    // font-size: 1.2rem;
  }

  .assessment-time,
  .team {
    font-size: bs.$font-size-small;
  }

  .date {
    display: inline-block;
  }

  .time {
    display: inline-block;
    white-space: nowrap;
  }

  .assessment-time {
    color: bs.$dark-gray;
  }

  .team {
    text-align: right;
    color: bs.$blue;
  }
}
