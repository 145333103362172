.live-assessment,
.live-assessment-review-detail {
  .question-overview {
    position: relative;
    padding: 0 0 100% 0;
    overflow: hidden;
    background-color: rgba($blue, 0.1);
    margin-bottom: 1em;
    font-size: 1rem;

    &.vote-red {
      background-color: rgba($red, 0.4);
      .response-data {
        color: $inverse-red;
      }
    }
    &.vote-yellow {
      background-color: rgba($yellow, 0.4);
      .response-data {
        color: $inverse-yellow;
      }
    }
    &.vote-green {
      background-color: rgba($green, 0.4);
      .response-data {
        color: $inverse-green;
      }
    }

    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0.5em;
      padding: 0;
      min-height: auto;
      margin-top: 0;
      margin-bottom: 0;
    }

    .question-title {
      font-size: 1.33em;
      margin-top: 0;
      grid-area: header;
      padding: 0.3em 0.5em 0;
    }

    .response-data {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      text-align: center;

      .response-count {
        font-size: 0.8em;
      }
    }
    div.bar-chart {
      height: 2.5em;
      width: 2.5em;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .no-results-chart {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      color: adjust-color($blue, $lightness: 30%, $saturation: -15%);
      text-align: center;
      align-self: center;
    }

    .total-response-count {
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem;
      color: adjust-color($blue, $lightness: 30%, $saturation: -15%);
      text-align: center;
      display: grid;
      height: 2.6667rem;
      align-items: center;
    }
    .total-response-count-value {
      font-size: 1.4em;
    }

    .response-result {
      position: absolute;
      bottom: 0.5em;
      left: 0.5em;
      color: adjust-color($blue, $lightness: 30%, $saturation: -15%);

      .result-icon {
        font-size: 2.6667em;

        &.vote-red {
          color: $inverse-red;
        }
        &.vote-yellow {
          color: $inverse-yellow;
        }
        &.vote-green {
          color: $inverse-green;
        }

        svg[data-icon='hand-paper'] {
          left: -0.05em;
        }
      }
    }
  }
}
