.live-assessment-list-item {
  .spark {
    position: relative;
    top: -0.4em;

    .question-set-overview {
      display: inline-block;
      margin: 0 1em 0 0;
      padding: 0;
    }

    .question-overview {
      border-radius: 0.1em;
      display: inline-block;
      background-color: $blue;
      height: 0.8em;
      width: 0.8em;
      margin: 0 0.25em 0 0;
    }
  }
}
