@import 'bootstrap/scss/_functions.scss';
@import './greenleaf/bootstrap_variables';
@import 'bootstrap/scss/bootstrap.scss';

@import './now-ui/variables.scss';
@import 'vendor/now-ui/assets/scss/now-ui-dashboard.scss';

@import './now-ui/overrides.scss';

@import './greenleaf/all';

// HACK: gets now-ui wrapper on dashboard to allow scrolling.
// Not sure why it isn't at this point, but something to resolve
// html {
//   height: 100vh;
// }
// body {
//   min-height: 100vh;
//   position: relative;
// }
#react-app,
#react-app > .wrapper,
.main-panel {
  height: auto;
  min-height: 100vh;
  overflow: visible;
}

.app-header {
  position: relative;
}

.wrapper.wrapper-assessment {
  overflow: visible;
}

body,
html {
  height: 100%;
}
