$wc: $white-color;
$bc: $black-color;

$lt-red: #ffeced;
$dk-red: #490104;
$lt-blue: mix($blue, $wc, 10%);
$dk-blue: mix($blue, $bc, 10%);
$lt-purple: #f6e9f4;
$dk-purple: #34032b;
$lt-green: #f2fcf1;
$dk-green: #063901;
$lt-cyan: #f1fcfc;
$dk-cyan: #013739;
$lt-yellow: #f2fcf1;
$dk-yellow: #063901;
$lt-orange: #f2fcf1;
$dk-orange: #063901;

// $reds: ($red, $wc), (mix($red, $wc, 10%), $wc), (mix($red, $wc, 20%), $wc), (mix($red, $wc, 30%), $wc),
// (mix($red, $wc, 40%), $wc), (mix($red, $wc, 50%), $wc), (mix($red, $wc, 60%), $wc), (mix($red, $wc, 70%), $wc),
// (mix($red, $wc, 80%), $wc), (mix($red, $wc, 90%), $wc);

// @function scale-monochrome($color, $scales) {
//   $result: ();
//   @each $scale in $scales {
//     $result: append($result, (scale-color($color, $lightness: $scale, $saturation: $scale), $wc));
//   }
//   @return $result;
// }

@function generate-increments(
  $start,
  $end,
  $increment,
  $passes: 1,
  $pass-increment: 0%
) {
  $result: ();
  $current: $start;
  $loop: 1;
  @while $loop <= $passes {
    @while $current <= $end {
      $result: append($result, $current);
      $current: $current + $increment;
    }
    $current: $start + $pass-increment * $loop;
    $loop: $loop + 1;
  }

  @return $result;
}

@function scale-monochrome(
  $color,
  $scales,
  $mix-color: $wc,
  $light-text: $wc,
  $dark-text: $bc
) {
  $result: ();
  @each $scale in $scales {
    $background: mix($mix-color, $color, $scale);
    $result: append(
      $result,
      (
        $background,
        choose-foreground-color($background, $light-text, $dark-text)
      )
    );
  }
  @return $result;
}

@function merge-palettes($base-palettes...) {
  $result: ();
  $lengths: ();
  @each $base-palette in $base-palettes {
    $lengths: append($lengths, length($base-palette));
  }
  $max-length: max($lengths...);
  @for $i from 1 through $max-length {
    @each $base-palette in $base-palettes {
      @if length($base-palette) >= $i {
        $result: append($result, nth($base-palette, $i));
      }
    }
  }
  @return $result;
}

@function calculate-brightness($color) {
  @return round(
    (red($color) * 299)+ (green($color) * 587)+ (blue($color) * 114)/1000
  );
}

// @function text-contrast($background, $text-dark, $text-light, $text-dark-mix: $body-color, $text-light-mix: #ffffff) {
//   $background-brightness: brightness($background);
//   $text-light-brightness: brightness($text-light);
//   $text-dark-brightness: brightness($text-dark);

//   @if abs($color-brightness) < ($light-color/2){
//     color: white;
//   } @else {
//     color: black;
//   }
// }

// @mixin text-contrast($n) {
//   $color-brightness: round((red($n)*299)+(green($n)*587)+(blue($n)*114)/1000);
//   $light-colorsssss: round((red($n)*299)+(green($n)*587)+(blue($n)*114)/1000);
//   @if abs($color-brightness) < ($light-color/2){
//     color: white;
//   } @else {
//     color: black;
//   }
// }

@function choose-foreground-color(
  $background,
  $foreground-dark,
  $foreground-light
) {
  $background-brightness: calculate-brightness($background);
  $foreground-light-brightness: calculate-brightness($foreground-light);
  $foreground-dark-brightness: calculate-brightness($foreground-dark);

  @if abs($background-brightness - $foreground-light-brightness) >
    abs($background-brightness - $foreground-dark-brightness)
  {
    @return $foreground-light;
  } @else {
    @return $foreground-dark;
  }
}

// @function make-accessible-contrast($background, $foreground, $threshold: 0.5) {
//   $background-brightness: brightness($background);
//   $foreground-brightness: brightness($foreground);
//   $difference: $background-brightness - $foreground-brightness;
//   $threshold: $threshold * 1000;

//   @if abs($difference) < $threshold {
//     @return scale-color($foreground, $lightness: $threshold - abs($difference));
//   } @else {
//     @return $foreground;
//   }
// }

// @function color-difference($foreground, $background) {
//   $r: (max(red($foreground), red($background))) - (min(red($foreground), red($background)));
//   $g: (max(green($foreground), green($background))) - (min(green($foreground), green($background)));
//   $b: (max(blue($foreground), blue($background))) - (min(blue($foreground), blue($background)));
//   $sum-rgb: $r + $g + $b;

//   @return $sum-rgb;
// }

// @function scale-monochrome-light($color, $scales) {
//   $result: ();
//   @each $scale in $scales {
//     $result: append($result, (scale-color($color, $lightness: $scale, $saturation: $scale), $wc));
//   }
//   @return $result;
// }
// @function scale-monochrome-dark($color, $scales) {
//   $result: ();
//   @each $scale in $scales {
//     $result: append($result, (mix($color, $bc, $scale), $wc));
//   }
//   @return $result;
// }
$lmr: 94%;
$dmr: 94%;

$light-reds: scale-monochrome(
  $red,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, $red, $lmr),
  $dark-text: mix($bc, $red, $dmr)
);
$light-greens: scale-monochrome(
  #88da81,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, #88da81, $lmr),
  $dark-text: mix($bc, #88da81, $dmr)
);
$light-yellows: scale-monochrome(
  $yellow,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, $yellow, $lmr),
  $dark-text: mix($bc, $yellow, $dmr)
);
$light-oranges: scale-monochrome(
  $orange,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, $orange, $lmr),
  $dark-text: mix($bc, $orange, $dmr)
);
$light-blues: scale-monochrome(
  $blue,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, $blue, $lmr),
  $dark-text: mix($bc, $blue, $dmr)
);
$light-purples: scale-monochrome(
  #ed87da,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $light-text: mix($wc, $purple, $lmr),
  $dark-text: mix($bc, $purple, $dmr)
);

$dark-reds: scale-monochrome(
  $red,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $red, $lmr),
  mix($bc, $red, $dmr)
);
// $dark-greens: scale-monochrome(#88da81, generate-increments(0%, 80%, 20%, 4, 5%), $bc, mix($wc, #88da81, $lmr), mix($bc, #88da81, $dmr));
$dark-greens: scale-monochrome(
  $green,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $green, $lmr),
  mix($bc, $green, $dmr)
);
$dark-yellows: scale-monochrome(
  $yellow,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $yellow, $lmr),
  mix($bc, $yellow, $dmr)
);
$dark-oranges: scale-monochrome(
  $orange,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $orange, $lmr),
  mix($bc, $orange, $dmr)
);
$dark-blues: scale-monochrome(
  $blue,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $blue, $lmr),
  mix($bc, $blue, $dmr)
);
$dark-purples: scale-monochrome(
  $purple,
  generate-increments(0%, 80%, 20%, 4, 5%),
  $bc,
  mix($wc, $purple, $lmr),
  mix($bc, $purple, $dmr)
);

// $light-reds: scale-monochrome($red, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-red, $dark-text: $dk-red);
// $light-greens: scale-monochrome(#88da81, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-green, $dark-text: $dk-green);
// $light-yellows: scale-monochrome($yellow, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-yellow, $dark-text: $dk-yellow);
// $light-oranges: scale-monochrome($orange, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-orange, $dark-text: $dk-orange);
// $light-blues: scale-monochrome($blue, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-blue, $dark-text: $dk-blue);
// $light-purples: scale-monochrome(#ed87da, generate-increments(0%, 80%, 20%, 4, 5%), $light-text: $lt-purple, $dark-text: $dk-purple);

// $dark-reds: scale-monochrome($red, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);
// $dark-greens: scale-monochrome(#88da81, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);
// $dark-yellows: scale-monochrome($yellow, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);
// $dark-oranges: scale-monochrome($orange, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);
// $dark-blues: scale-monochrome($blue, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);
// $dark-purples: scale-monochrome(#ed87da, generate-increments(0%, 80%, 20%, 4, 5%), $bc, $light-text: $lt-red, $dark-text: $dk-red);

$chart-colors-light: merge-palettes(
  $dark-blues,
  $dark-purples,
  $dark-yellows,
  $dark-greens,
  $dark-oranges,
  $dark-reds
);
$chart-colors-dark: merge-palettes(
  $light-blues,
  $light-purples,
  $light-yellows,
  $light-greens,
  $light-oranges,
  $light-reds
);
// $o-chart-colors-dark: merge-palettes($o-light-blues, $o-light-purples, $o-light-greens, $o-light-oranges, $o-light-reds);
// $chart-colors-dark: merge-palettes($light-reds);
// $chart-colors-dark: (#28b3d1, #f6feff), (#ed87da, #34032b);

// prettier-ignore
$categorization-colors: 
(#9d4c8e, #f6e9f4), (#4c9745, #f2fcf1), (#28b3d1,#f6feff), (#f0dd2b, #3d3700), (#ea9727, #f7f3ed), (#4643c8, #ebeaf7), (#ed2228, #fff2f2),
(#712563, #f6e9f4), (#25691E, #f2fcf1), (#02758d,#f6feff), (#bcaa03, #3d3700), (#9c5c07, #f7f3ed), (#17148c, #ebeaf7), (#a00f14, #fff2f2),
(#ED87DA, #34032b), (#88DA81, #063901), (#70dbf2,#023541), (#f7eb7e, #3d3700), (#fbb65a, #3b2200), (#7e7be0, #010022), (#fc8a8e, #240001);

$chart-colors: (#28b3d1, #f6feff), (#9d4c8e, #f6e9f4), (#4c9745, #f2fcf1),
  (#ea9727, #f7f3ed), (#4643c8, #ebeaf7), (#ed2228, #fff2f2), (#02758d, #f6feff),
  (#712563, #f6e9f4), (#25691e, #f2fcf1), (#9c5c07, #f7f3ed), (#17148c, #ebeaf7),
  (#a00f14, #fff2f2), (#70dbf2, #023541), (#ed87da, #34032b), (#88da81, #063901),
  (#fbb65a, #3b2200), (#7e7be0, #010022), (#fc8a8e, #240001);

$chart-dark-colors: (#28b3d1, #f6feff), (#ed87da, #34032b), (#88da81, #063901),
  (#ea9727, #f7f3ed), (#4643c8, #ebeaf7), (#ed2228, #fff2f2), (#70dbf2, #023541),
  (#712563, #f6e9f4), (#25691e, #f2fcf1), (#9c5c07, #f7f3ed), (#17148c, #ebeaf7),
  (#a00f14, #fff2f2), (#02758d, #f6feff), (#9d4c8e, #f6e9f4), (#4c9745, #f2fcf1),
  (#fbb65a, #3b2200), (#7e7be0, #010022), (#fc8a8e, #240001);

$tiers-colors: (#9d4c8e, $wc), (#28b3d1, $bc), (#4643c8, $wc), (#712563, $wc),
  (#02758d, $wc), (#17148c, $wc), (#ed87da, $wc), (#70dbf2, $bc), (#7e7be0, $bc);

@mixin palette($name, $colors) {
  @for $i from 1 through length($colors) {
    $palette-class: '.palette-#{$name}-#{$i}';
    $palette-item-colors: nth($colors, $i);
    $bg-color: nth($palette-item-colors, 1);
    $color: nth($palette-item-colors, 2);

    #{$palette-class} {
      background-color: $bg-color;
      fill: $bg-color;
      color: $color;
    }

    .btn#{$palette-class},
    button#{$palette-class} {
      &:focus,
      &:hover {
        background-color: darken($bg-color, 5%);
        color: $color;
      }
    }

    .muted #{$palette-class},
    #{$palette-class}.muted,
    #{$palette-class}.muted-bg {
      background-color: rgba($bg-color, 0.2);
      color: $black-color;
    }

    #{$palette-class}-border {
      border-color: $bg-color;
      background-color: unset;
    }
  }
}

@mixin palette-chart($name, $colors, $muted-mix-color: $white-color) {
  @for $i from 1 through length($colors) {
    $palette-class: '.palette-#{$name}-#{$i}';
    $palette-item-colors: nth($colors, $i);
    $bg-color: nth($palette-item-colors, 1);
    $color: nth($palette-item-colors, 2);

    #{$palette-class} {
      .palette-stroke {
        stroke: $bg-color;
        fill: none;
      }
      .palette-fill {
        fill: $bg-color;
      }

      &.palette-muted {
        .palette-stroke {
          stroke: mix($bg-color, $muted-mix-color, 35%);
        }
        .palette-fill {
          fill: mix($bg-color, $muted-mix-color, 35%);
        }
      }
    }
  }
}

@include palette('cat', $categorization-colors);
@include palette('tiers', $tiers-colors);
@include palette-chart('chart', $chart-colors);
@include palette-chart('chart-dark', $chart-colors-dark, $black-color);
@include palette-chart('chart-light', $chart-colors-light, $black-color);
