@use '/src/styles/greenleaf/bootstrap_variables' as bs;

$stroke-width: 1.5;

.assessment-trends-chart {
  :global(.line) {
    stroke-width: $stroke-width;
    path {
      stroke-width: $stroke-width;
    }
  }
}

.vote-count-glyph {
  .bar {
    // fill: currentColor;
    stroke: none;
  }
  .spacer {
    fill: bs.$black-color;
    stroke: none;
  }
  .outer-ring {
    // fill: currentColor;
    stroke: none;
  }
  .inner-circle {
    fill: bs.$black-color;
    stroke: none;
  }
}
