@font-face {
  font-family: 'Harabara Mais';
  src: url('/fonts/harabara-mais.eot');
  src: url('/fonts/harabara-mais.eot') format('embedded-opentype'),
  url('/fonts/harabara-mais.woff2') format('woff2'),
  url('/fonts/harabara-mais.woff') format('woff'),
  url('/fonts/harabara-mais.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
