.survey-assessment.review {
  .assessment-summary {
    margin-bottom: $margin-large-vertical;
  }

  .aggregate-history {
    margin-top: $margin-large-vertical;
    max-width: 2000px;
    display: inline-block;
  }

  .category-bar {
    display: block;
    height: 3px;
    width: 100%;
    margin-top: -4px;
    margin-bottom: 4px;
  }
}
