.background-page {
  color: #fff;

  label {
    color: $body-subdued-font-color;
  }

  fieldset {
    margin-bottom: 1.5em;
  }

  fieldset:last-of-type {
    margin-bottom: 0;
  }
}

.full-page .full-page-background {
  background: url(/images/cartographer.png) -35px -200px repeat;
  z-index: 2;
}
