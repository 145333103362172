.badge {
  height: 1.5rem;
  min-width: 1.5rem;
  text-align: center;
  font-size: 0.8rem;
  background-color: $primary-color;
  color: $white;
  border: 0 solid transparent;
}

.badge.secondary {
  background-color: $secondary;
}

.badge {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 1000px;
}

.badged-icon {
  position: relative;
  display: inline-block;

  .badge {
    position: absolute;
    bottom: -0.75rem;
    right: -0.75rem;
  }
}
