#registration-page {
  h1 {
    margin-bottom: 0;
  }

  legend {
    // color: $light-gray;
    small {
      color: $info-color;
    }
  }

  .full-page-background {
    background: url(/images/cartographer.png) -35px -200px repeat;
    z-index: 2;
  }

  .registration-form .header-icon {
    color: $logo-blue;
  }
  .btn {
    font-size: 1.667rem;
  }

  input {
    background-color: $white-color;
    font-size: 2rem;
  }
}
