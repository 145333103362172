.live-assessment .wrapup {
  .question-overview {
    cursor: pointer;
  }

  .summary-bar {
    h2 {
      margin: 10px 1px;
    }
  }
}
