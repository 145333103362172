.input-lg {
  font-size: 1.667rem;

  input {
    padding: 1rem 0.75rem;
  }
}

form .form-actions {
  text-align: right;
}

.warning-feedback {
  .btn-group {
    display: inline-block;
  }

  .btn.btn-link {
    font-weight: bold;
    font-size: 100%;
    margin: 0 0 0 1em;
    padding: 0;
    vertical-align: baseline;
  }
}

@include form-validation-state-additions(
  'warning',
  $form-feedback-icon-warning-color,
  $form-feedback-icon-warning
);

form .alert label {
  color: white;
}

form button {
  position: relative;
  display: inline-block;
}

form button .spinner-grow {
  display: inline-block;
  position: absolute;
  right: 3px;
}

button .spinner-grow {
  display: inline-block;
  position: absolute;
  right: 15px;
}

form .decorator-link {
  margin-top: -0.5rem;
  text-align: right;
}

.multi-select-check-boxes {
  .choices {
    overflow-y: scroll;
    border: 1px solid lightgray;
  }
  .choice {
    padding: 3px 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    &.selected {
      background: lighten($success-color, 50%);
    }
    &:hover {
      background: $background-impression-light-color;
      &.selected {
        background: lighten($success-color, 53%);
      }
    }
  }
  .error-msg {
    color: $error-color;
    font-size: 0.8em;
  }
}

.custom-checkbox {
  .decorator {
    display: inline-block;
    margin-right: 0.5em;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-small;
    background-color: $white;
    width: 1.5em;
    height: 1.5em;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0.15em;
  }

  label {
    color: $black-color;
  }

  input[type='checkbox'] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }

  svg {
    opacity: 0;
  }
  label:hover .decorator {
    background-color: lighten($primary-color, 50%);
  }

  input[type='checkbox']:checked + .decorator {
    svg {
      opacity: 1;
    }
  }

  input[type='checkbox']:focus + .decorator,
  input[type='checkbox']:active + .decorator {
    border: 1px solid $primary-color;
  }
}

textarea.form-control {
  padding: 0.375rem 0.75rem;
}

textarea.autosize {
  max-height: unset;
}

.invalid-feedback.force-show {
  display: block;
}

.markdown-input {
  position: relative;
  border-radius: $border-radius;

  &.is-invalid {
    .invalid-feedback {
      display: block;
    }

    .markdown-overlay-button {
      border: 1px solid $error-color;

      &:hover {
        border-color: $error-color;
      }
    }
  }
  .markdown-input-container {
    position: relative;
  }
  .markdown-input-placeholder {
    font-style: italic;
    padding: 10px 18px;
    font-size: $font-size-small;
  }

  .markdown-input-markdown {
    border-radius: $border-radius;
    padding: 10px 18px;
  }

  .markdown-overlay-button {
    --border-padding: 0;
    font-size: $font-size-small;

    position: absolute;
    top: var(--border-padding);
    right: var(--border-padding);
    bottom: var(--border-padding);
    left: var(--border-padding);
    z-index: 100;
    cursor: text;
    border-radius: $border-radius;

    &:hover {
      border: 1px solid $primary-color;
    }
  }
}
