@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.panel-header {
  grid-area: header;
}

.editor {
  grid-area: editor;
}

.preview {
  background-color: bs.$smoke-bg;
  padding: 1rem;
  font-size: bs.$font-size-small;
  border-radius: bs.$border-radius-small;
  min-height: 88px;
}
