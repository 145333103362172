.user-list .expired .status {
  color: var(--danger);
}

.user-list .invite {
  font-style: italic;
}

.user-list .user,
.user-list .invite {
  padding: 15px 0;
  border-bottom: 1px solid $hr-card;
  display: grid;
  grid-template-columns: 26px 6fr 4fr 2fr 18px 150px 150px;
  grid-template-areas: 'icon username display-name role role-action status actions';
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  align-items: center;
}

.user-list .user:last-child,
.user-list .invite:last-child {
  border-bottom: none;
}

.user-list .icon {
  grid-area: icon;
  font-size: 0.9em;
  position: relative;
  top: 3px;
}
.user-list .username {
  grid-area: username;
  //font-size: 1.1em;
}
.user-list .display-name {
  grid-area: display-name;
  color: var(--gray);
}

.user-list .status {
  grid-area: status;
  font-size: 0.8em;
}

.user-list .actions {
  grid-area: actions;
  text-align: right;
}

.user-list .role {
  grid-area: role;
  color: var(--gray);
}

.user-list .role-action {
  grid-area: role-action;
  padding-left: 8px;
  cursor: pointer;
}

@media screen and (min-width: 716px) and (max-width: 1200px) {
  .user-list .user,
  .user-list .invite {
    display: grid;
    grid-template-columns: 26px 300px 2fr 2fr 1fr;
    grid-template-areas:
      'icon username role status actions'
      'icon display-name role status actions'
      'icon . role status actions'
      'icon . role status actions';
  }

  .user-list .display-name .missing {
    display: none;
  }
}

@media screen and (max-width: 715px) {
  .user-list .user,
  .user-list .invite {
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-template-areas:
      'icon username'
      '. display-name'
      '. role'
      '. status'
      '. actions';
  }
  .user-list .actions {
    text-align: left;
  }

  .user-list .display-name .missing {
    display: none;
  }
}
