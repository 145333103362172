.assessment-group-summary {
  margin-bottom: 1.5em;

  .action-items {
    display: block;
    margin-bottom: 0.25em;

    .btn:first-of-type() {
      margin-left: 0;
    }
  }
  @media #{$medium-up} {
    .action-items {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 0.5em;
    }
  }
}
