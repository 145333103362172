@use '/src/styles/greenleaf/bootstrap_variables' as bs;

:global(.differential-item-marker) {
  transition: all 0.75s ease;

  &:global(.to) {
    fill: bs.$blue;
    stroke: bs.$blue;
  }
  &:global(.from) {
    fill: bs.$black-color;
    stroke: bs.$orange;
  }
  &:global(.from-ghost) {
    fill: none;
    stroke: rgba(bs.$orange, 0.6);
  }
}

:global(.differential-item-connector) {
  transition: all 0.75s ease;

  stroke: bs.$no-response-marker-color;
  stroke-width: 1;

  &:global(.overlapped) {
    opacity: 0;
  }
}
:global(.arrow-marker) {
  fill: bs.$no-response-marker-color;
  stroke: none;
  stroke-width: 1;
}

:global(.differential-item-x) {
  stroke: bs.$red;
  stroke-width: 1.5;
  fill: none;
  stroke-linecap: round;
}
