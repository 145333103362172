.participation-stats {
  .stat-summary {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    width: 100%;
  }

  /* prettier-ignore */
  @if 1 {
    .invites svg { color: $blue; }
    .participants svg { color: $blue; }
    .not-started svg { color: $red; }
    .in-progress svg { color: $yellow; }
    .completed svg { color: $green; }
  }
}

.completion-rate-chart text {
  stroke: $white;
  fill: $white;
}

.completion-rate-chart-area {
  &.fill {
    fill: rgba($blue, 0.5);
  }
  &.stroke {
    stroke: $blue;
  }
}
