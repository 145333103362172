@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.editor {
  --header-height: 60px;
  min-width: 680px;
  overflow-x: auto;
  position: relative;
  display: grid;
  height: 100%;
  grid-template-areas:
    'mainHeader mainHeader'
    'main settings';
  grid-template-columns: minmax(650px, 1fr) minmax(300px, 500px);
  grid-template-rows: min-content 1fr;
  background-color: #eeeeee;

  @media (max-width: 1050px) {
    grid-template-columns: 100% 0;
  }

  &.preview {
    grid-template-areas:
      'mainHeader mainHeader'
      'main main';

    :global(.question-answers) label {
      color: bs.$black-color;
    }

    .mainPane {
      padding-top: 0;
    }
  }

  label {
    font-size: bs.$font-size-small;
    margin-bottom: 5px;
    color: bs.$dark-gray;
  }
}

.mainHeader {
  grid-area: mainHeader;
  height: var(--header-height);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  margin-right: 10px;

  .nav {
    height: 100%;
    display: flex;
    align-items: center;

    > a {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 14px 20px;
      display: block;

      &:hover {
        text-decoration: none;

        &:not(.active) {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      font-size: 18px;
      color: black;

      border-bottom: 3px solid transparent;

      &.active {
        cursor: default;
        border-bottom: 3px solid bs.$blue;
      }
    }
  }

  .headerActions {
    display: flex;
    align-items: center;
    gap: 15px;

    .grouping {
      display: flex;
      column-gap: 2px;

      button.iconButton {
        border: none;
        outline: none;
        font-size: 24px;

        &:hover:not(:disabled) {
          background: rgba(0, 0, 0, 0.1);
        }

        &:not(:disabled) {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      &:not(:last-child) {
        padding-right: 15px;
        border-right: 2px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.mainPane {
  position: relative;
  padding: 10px 0;
  grid-area: main;
  height: calc(100vh - var(--header-height) - bs.$minimal-layout-header-height);
  overflow-y: auto;
  scrollbar-width: thin;

  &.noscroll {
    overflow-y: hidden;
  }
}

.mainPaneOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.settingsPane {
  // padding: 10px;
  grid-area: settings;
  height: calc(100vh - var(--header-height) - bs.$minimal-layout-header-height);

  @media (max-width: 1050px) {
    position: absolute;
    right: 0;
    width: 500px;
    z-index: 2;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #eeeeee;
  }
}

.toolPane {
  // background: white;
  padding: 10px 5px;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-height: calc(
    100vh - var(--header-height) - bs.$minimal-layout-header-height
  );
  overflow-y: auto;
  scrollbar-width: thin;
}

.assessment {
  position: relative;
  grid-area: assessment;
  padding: 0 15px;
}

.tools {
  grid-area: tools;
  padding: 10px 10px 10px 0;
}

.assessmentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-set-name,
.assessment-name {
  font-size: 1.5rem;
  margin: 0;

  :global(.invalid-feedback) {
    font-size: 0.8rem;
    margin-top: -7px;
  }
}

.question-set {
  position: relative;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  &.draggedOver {
    border-width: 2px;
    border-color: rgba(0, 0, 255, 0.5);
  }

  .dropInd.top {
    top: -11px;
    bottom: unset;
  }

  .dropInd.bottom {
    bottom: -13px;
    top: unset;
  }
}

.metadata {
  position: relative;
  border-radius: 3px;
  // padding: 0.5rem;
  // background-color: lighten(bs.$info-color, 40%);
  background-color: bs.$smoke-bg;
  // border-color: bs.$info-color;
  border: 2px solid bs.$dark-gray;
  padding: calc(0.5rem - 3px);
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  legend {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.metadata-grid {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-areas:
    'metadata-key metadata-type'
    'metadata-name metadata-author'
    'metadata-description metadata-description';

  .metadata-key {
    grid-area: metadata-key;

    .key {
      padding: 3px 3px 0;
    }
  }

  .metadata-name {
    grid-area: metadata-name;
  }

  .metadata-type {
    grid-area: metadata-type;
    padding: 0 0.5rem 1px;
    align-content: end;
    text-align: right;
    white-space: nowrap;
    color: bs.$body-subdued-font-color;

    :global(.assessment-type-glyph) {
      font-size: 1.25rem;
      position: relative;
      top: 0.125rem;
      margin-top: -0.125rem;
    }
  }

  .metadata-author {
    grid-area: metadata-author;
    text-align: right;
    padding: 14px 0.5rem 0;
    font-size: bs.$font-size-small;
  }

  .metadata-description {
    grid-area: metadata-description;
  }
}

.meta-key-value {
  display: inline-block;
  min-width: 30em;
  // margin-left: 0.3em;

  :global(.editor-input:has(input)) {
    margin: -2.625px 0;
  }
}

.question-set-header,
.question-set-details {
  background-color: #e0eff3;
}
.question-set-details {
  padding: 0 0.5rem 0.5rem;
}

.question {
  position: relative;
  border-radius: 3px;
  border: 1px solid bs.$blue;
  margin: 10px 0.5rem;

  .dropInd.top {
    top: -8px;
    bottom: unset;
  }

  .dropInd.bottom {
    bottom: -9px;
    top: unset;
  }
}

.questionDetail {
  margin: 0 0.5rem 0.5rem 0.5rem;
}

.long-text-detail {
  textarea {
    width: 100%;
    resize: none;
  }
}

input.questionSetDescription {
  font-style: italic;
  font-size: 0.9rem;
}

.panel-header {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
}

.questionHeader {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionCount {
  font-size: 0.9rem;
  color: bs.$dark-gray;
  margin-left: 5px;
  display: inline-block;
}

.key {
  color: bs.$body-subdued-font-color;
  font-size: 0.8rem;
}

.keyError {
  color: red;
  font-size: 0.8rem;
}

.type {
  align-self: center;

  :global(.action-selector .dropdown-toggle) {
    width: 14em;
  }
}

.actions {
  align-self: center;
}

.body,
.key,
.type {
  padding: 0.5rem;
}

.actionList {
  margin: 0 10px;
}

.addButtonContainer {
  margin: 10px 0 50px;
}

.addButton {
  border: none;
  outline: none;
  background-color: white;
  width: 100%;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  i {
    color: bs.$primary-color;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #e0eff3;
  }
}

button:global(.drag-handle) {
  @extend .iconButton;
  cursor: grab;
}

button.expander {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  color: bs.$primary-color;

  // border-radius: 17px;
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  transition: transform 0.1s;
}

button.expander.collapsed {
  transform: rotate(-90deg);
}

.question {
  :global(.dropdown .dropdown-menu:not(.show)) {
    display: none;
  }
}

.dragging {
  opacity: 0.2;
}

.draggedOver-top {
  .dropInd.top {
    visibility: visible;
  }
}

.draggedOver-bottom {
  .dropInd.bottom {
    visibility: visible;
  }
}

.dropInd {
  position: absolute;
  height: 5px;
  background-color: bs.$blue;
  width: calc(100% + 0px);
  opacity: 0.5;
  border-radius: 5px;
  // top: 50%;
}

.question-set {
  :global(.badge) {
    text-transform: unset;

    button {
      padding: 0;
      outline: none;
      background-color: transparent;
      border: none;
      color: inherit;
    }
  }
}

input.editableText.badge {
  width: unset;
  padding: 5px 10px;
  border-radius: 5px;
  flex-grow: 0;
}

.catContainer {
  display: flex;
  margin-bottom: 5px;
  gap: 10px;
}

.badge {
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 15px;
  border: none;
  margin: 0;
  vertical-align: middle;

  &.uncategorizedBadge {
    color: white;
    background-color: bs.$dark-gray;

    &:hover,
    &:focus {
      background-color: darken(bs.$dark-gray, 5%);
    }
  }
}

.itemToDelete {
  margin-top: 10px;
  font-weight: bold;
  line-height: 1.3;
}

.Alert {
  position: fixed;
  display: flex;
  justify-content: center;
  // width: calc(100% - 200px);
  width: 100vw;
  top: 10px;
  left: 0;
  z-index: 1500;

  :global(.alert button.close span) {
    font-size: 35px;
    font-weight: lighter;
  }
}

.alertContent {
  margin-right: 20px;

  button {
    border: none;
    margin: 0 0 0 10px;
    padding: 0;
    background-color: transparent;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  }
}

.categorySelector {
  flex-grow: 1;
  text-align: right;

  :global(.combobox-items) {
    right: 0;
  }
}

.CategoryListing {
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;

  .Marker {
    height: 12px;
    width: 12px;
  }

  :global(.drag-handle) {
    margin-right: 10px;
  }

  .dropInd.top {
    top: -2px;
    bottom: unset;
  }

  .dropInd.bottom {
    bottom: -2px;
    top: unset;
  }
}

.iconButton {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: bs.$body-subdued-font-color;

  &:focus {
    outline: none;
  }
}

.fullWidth {
  width: 100%;
}

.addButtonOption {
  text-align: center;
}

.settings-wrapper {
  .settings-drawer {
    background-color: bs.$smoke-bg;
    padding: 0.5rem;
    border: 1px solid bs.$dark-gray;
    border-left: none;
    border-right: none;
    margin-bottom: 1rem;

    :global(.answer-set-selector) {
      :global(.dropdown-toggle) {
        width: unset;

        > div {
          width: 15em;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

:global(.hide-settings) {
  .settings-drawer {
    display: none;
    visibility: hidden;
  }
}
:global(.setting-only) {
  display: none;
  visibility: hidden;
}
:global(.setting-only) {
  display: none;
  visibility: hidden;
}

.tooltip :global(.tooltip-inner) {
  font-size: 0.8rem;
}
