.details-chart,
.chart-tip {
  color: $white;

  .vote-red {
    fill: $red;
  }
  .vote-yellow {
    fill: $yellow;
  }
  .vote-green {
    fill: $green;
  }
  .vote-none {
    fill: $blue;
    color: $blue;
  }
}

.details-chart-content {
  position: relative;

  .details-chart-label {
    color: $body-subdued-font-color;
  }
}
.details-chart-label {
  font-size: 0.8rem;
  position: absolute;
  z-index: 2;
  top: 1rem;
  left: 2rem;
}

.question-set-overview-chart {
  svg {
    overflow: visible;
  }
  svg .marker * {
    transition: all 0.75s ease;
  }
  svg .marker .marker-highlight * {
    transition: all 0.75s ease, fill 0s ease;
  }
}
.marker:hover .tip {
  display: inherit;
}
.marker .no-responses {
  .rect-post,
  .post-top {
    fill: $no-response-marker-color;
  }
}

.marker-highlight {
  circle,
  rect {
    fill: none;
  }

  &.transient {
    circle,
    rect {
      fill: $orange;
    }
  }

  &.locked {
    circle,
    rect {
      fill: $blue;
    }
  }

  &.locked,
  &.transient {
    circle,
    rect {
      &.post-outer-outline {
        fill: $black-color;
      }
    }
  }

  circle,
  rect {
    &.post-outline {
      fill: $black-color;
    }
  }
}

.hit-box {
  opacity: 0;
  fill: rgba(#000, 0);
}

.axis path,
.axis line {
  fill: none;
  stroke: $chart-stroke-color;
  //  stroke: #7695b2;
  shape-rendering: crispEdges;
}
.axis text {
  display: none;
}
.y.axis .tick {
  display: none;
}

.post-hole {
  fill: $black-color;
}

.bounding-box,
.tick {
  fill: none;
  stroke: $chart-stroke-color;
  stroke-width: 1.5px;
}

.bounding-line,
.tick-line {
  stroke: $chart-stroke-color;
  shape-rendering: crispEdges;
  stroke-width: 1.5px;
}

.tick-line {
  fill: none;
  stroke-width: 1px;
  stroke-dasharray: 1, 6;
}
.tick-label {
  fill: $chart-stroke-color;
  font-size: 10px;
}
.tick-label-highlight {
  fill: lighten($color: $chart-stroke-color, $amount: 30%);
}
.line {
  fill: none;
  stroke: $blue;
  stroke-width: 1.5px;
}

.bounds {
  fill: rgba($blue, 0.2);
  stroke: rgba($blue, 0.8);
  stroke-width: 1px;
}

.tip-bg {
  fill: rgba(#222, 0.8);
}

.question-change-indicator {
  &.vote-red {
    color: $red;
  }
  &.vote-yellow {
    color: $yellow;
  }
  &.vote-green {
    color: $green;
  }
  &.vote-none {
    color: $blue;
  }
}

@media #{$large-up} {
  .details-filter-selector {
    margin: 0.5em 0;
  }
}

.select-question {
  .question-overview {
    background-color: rgba($blue, 0.1);
    padding: 0 0 100% 0;
    position: relative;

    &:hover {
      background-color: rgba($red, 0.2);
    }

    .question-title {
      display: none;
      font-weight: normal;
      position: relative;
      z-index: 2;
    }

    @media #{$medium-up} {
      .question-title {
        display: block;
        font-size: 0.6rem;
      }
    }

    @media #{$large-up} {
      .question-title {
        font-size: 0.8rem;
      }
    }
  }

  @media #{$medium-only} {
    .question-overview .content {
      padding: 0.1rem 0.2rem;
    }
  }

  .content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .question-change-indicator {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 6vw;

    svg[data-icon] {
      margin-top: -50%;
    }
  }

  @media #{$medium-up} {
    .question-change-indicator {
      font-size: inherit;
      line-height: inherit;
      top: auto;
      left: auto;
      bottom: 0rem;
      right: 0.25rem;
    }
  }
}

.no-history {
  font-size: 3rem;
  position: absolute;
  top: 97px;
  line-height: 0;
  text-align: center;
  width: 100%;
  color: $chart-stroke-color;
}
