#login-page {
  max-width: $medium-breakpoint;

  .full-page-background {
    background: url(/images/cartographer.png) -35px -200px repeat;
    z-index: 2;
  }

  .card-login .logo-container {
    text-align: center;
    // margin-bottom: 35px;
  }

  .main-logo {
    color: $logo-blue;
  }

  .btn {
    font-size: 1.667rem;
  }
}

@media #{$medium-up} {
  #login-page {
    margin: 0 auto;
  }
}
