$tally-transition-speed: 500ms;

.vote-red {
  fill: $red;
}
.vote-yellow {
  fill: $yellow;
}
.vote-green {
  fill: $green;
}

.vote-tally-wrapper {
  position: relative;
}

.vote-tally {
  .horizontal-bar-chart .d3 {
    border-left: 1px solid #000;
    width: 100%;
    box-sizing: border-box;
  }
  .bar-chart .d3 {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  &.bar {
    .vote-tally-chart .no-results {
      top: 30%;
      left: 0;
      right: 0;
      padding-left: 0.2rem;
      text-align: center;
      font-size: 0.6rem;
      line-height: 1.1;
    }
  }
}

.vote-tally-chart {
  position: relative;
  font-size: 0;

  .no-results-label {
    font-size: 0.9rem;
  }
}

// .vote-tally-count {
//   // font-size: 0.9rem;
//   .badge { display: none; }
// }

.bar-chart,
.horizontal-bar-chart,
.no-results-label {
  transition: opacity $tally-transition-speed;
}

.no-results {
  .bar-chart,
  .horizontal-bar-chart {
    opacity: 0;
  }
  .no-results-label {
    opacity: 1;
    z-index: 2;
  }
}

.vote-tally-count,
.no-results-label {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.no-results-label {
  z-index: -1;
  color: adjust-color($blue, $lightness: 30%, $saturation: -15%);
  opacity: 0;
}

.vote-tally-count-wrapper,
.no-results-wrapper {
  display: table;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}
.no-results-wrapper {
  padding-right: 0;
}

.vote-tally-count-icon,
.vote-tally-count-timer,
.no-results-icon {
  display: table-cell;
  width: 50%;
  vertical-align: middle;

  svg[data-icon] {
    margin-right: 0;
  }
}

.no-results-icon svg[data-icon] {
  display: none;
}
.no-results-text {
  font-size: 1.667em;
  line-height: 1.3em;
}

@media #{$small-only} {
  .no-results-wrapper {
    padding: 0;
  }
  .no-results-text {
    font-size: 0.75rem;
    text-align: center;
    display: block;
    position: relative;
    top: -0.333em;
  }
}

.vote-tally-compact {
  .no-results-wrapper {
    padding: 0;
  }

  .no-results-text {
    font-size: 0.75rem;
    text-align: center;
    display: block;
    position: relative;
    top: -0.333em;
    margin-left: 0;
  }

  .no-results-icon {
  }

  .no-results-icon svg[data-icon] {
    display: none;
  }
}

@media #{$xlarge-up} {
  .no-results-label,
  .vote-tally-count {
    position: absolute;
    top: -0.5rem;
  }

  .no-results-text {
    margin-left: 0.5em;
    position: relative;
    top: -0.3em;
  }

  .no-results-icon svg[data-icon] {
    display: inline-block;
  }
}

.vote-tally-count-timer {
  padding-left: 0;
  text-align: right;

  svg[data-icon],
  a {
    color: $action-color;
  }

  .countdown-clock {
    display: block;
    font-size: 2rem;
  }
}

.vote-tally-show {
  // font-size: 1rem;
  // line-height: 1;
}

.vote-tally-wrapper,
.vote-tally-item {
  .vote-tally {
    opacity: 0;
  }
  .vote-tally-icon,
  .vote-tally-count {
    opacity: 1;
  }

  &.show-results {
    .vote-tally {
      opacity: 1;
      transition: opacity $tally-transition-speed;
    }
    .vote-tally-icon,
    .vote-tally-count {
      opacity: 0;
      transition: opacity $tally-transition-speed;
    }
  }
}

.vote-tally-item {
  position: relative;

  .vote-tally {
    position: absolute;
    left: 0;
    top: 0.3em;
    z-index: 200;
  }
}
