.skeleton-bone {
  background-color: $skeleton-bone-color;
  border-radius: $border-radius-small;
}

.skeleton-bone-after:after {
  content: '';
  display: block;
  background-color: $skeleton-bone-color;
  border-radius: $border-radius-small;
}
