@mixin vote-for($choice, $color, $inverse-color, $vote-color) {
  &.vote-#{$choice} {
    button.vote-#{$choice},
    .button.vote-#{$choice} {
      background-color: $vote-color;

      color: adjust-color($color, $lightness: -30%, $saturation: 15%);
    }
  }

  button.vote-#{$choice},
  .button.vote-#{$choice} {
    .fa,
    .svg-inline--fa {
      color: $color;
    }

    .fa-inverse {
      color: $inverse-color;
    }
  }
}

.voting-booth {
  $inverse-red: adjust-color($red, $lightness: 50%, $saturation: -15%);
  $inverse-yellow: adjust-color($yellow, $lightness: -30%, $saturation: 15%);
  $inverse-green: adjust-color($green, $lightness: 50%, $saturation: -15%);

  @include vote-for(
    $choice: 'red',
    $color: $red,
    $inverse-color: $inverse-red,
    $vote-color: $poor-background
  );
  @include vote-for(
    $choice: 'yellow',
    $color: $yellow,
    $inverse-color: $inverse-yellow,
    $vote-color: $meh-background
  );
  @include vote-for(
    $choice: 'green',
    $color: $green,
    $inverse-color: $inverse-green,
    $vote-color: $good-background
  );

  &.vote-yellow button.vote-yellow {
    color: $inverse-yellow;
  }

  button,
  .button {
    &:focus {
      outline: none;
    }
  }
}

.ballot {
  padding: 0 4rem 0 0;
  position: relative;
}
.ballot-nav {
  position: absolute;
  right: 0.5rem;
  top: 3.2rem;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 2.2rem;
    line-height: 1em;

    li {
      padding: 0;
      margin: 0 0 0.2rem;
    }
  }

  a {
    text-align: center;
    padding: 0.3rem 0;
    display: block;
    color: $purple;

    &:hover {
      color: $blue;
    }
  }

  .ballot-nav-sync a,
  .ballot-nav-next.disabled a,
  .ballot-nav-previous.disabled a {
    color: adjust-color($purple, $saturation: -30%, $lightness: 20%);
  }
  .ballot-nav-sync.synced a {
    color: $purple;
  }

  .badged-icon .badge {
    bottom: -0.5rem;
  }

  .disabled {
  }
}

.status-info-placeholder {
  float: right;
  height: 2rem;
  width: 4.75rem;
}
.status-info {
  position: absolute;
  right: 0.5rem;
  top: 0;
  padding-right: 0.3rem;
  font-size: 1.76rem;
  line-height: 2.2rem;

  .info-item {
    margin-left: 1.25rem;
    padding: 0.3rem 0;
    text-align: center;
    display: inline-block;
  }
  .vote-tally {
    width: 2rem;
    border-left: 0;
    svg {
      height: 1.66rem;
    }
  }
}

.ballot-review {
  font-size: 0.8rem;

  .review-title {
    padding-top: 0.3em;
    font-size: 1.6875rem;
    margin-bottom: 0;
  }

  .subtext {
    font-size: 0.8rem;
    color: $text-muted;
  }

  .responses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    column-gap: 10px;
    justify-content: start;

    .question-set {
      grid-column-end: -1;
      grid-column-start: 1;
      height: 0;
    }
  }

  .question-overview {
    &:hover {
      border: 2px solid $purple;
      margin: -2px;
      border-radius: $border-radius;
    }

    .content {
      font-size: 0.8rem;
    }

    .question-title {
      font-size: 0.7rem;
      color: $black-color;
      padding-right: 2rem;
    }

    .response-result {
      right: 0.5em;
      left: auto;
      font-size: 0.6rem;
    }

    .no-results-chart,
    .response-data {
      display: none;
    }
  }
}
