.business-goal {
  .stamp {
    position: absolute;
    top: 1em;
    right: 1.6em;
  }

  &.primary {
    .stamp {
      color: #e07979;

      svg {
        transition: all 0.2s;
        transform: rotate(23deg);
      }
      .background-layer {
        color: #f8fc0a;
        filter: none !important;
      }
    }
  }
}
