.naBadge {
  position: absolute;
  right: 10px;
  top: -10px;
  display: table;
  height: 25px;
  width: auto;
  background-color: #28b3d1;
  border-radius: 14px;
}

.naCount {
  color: white;
  font-size: 12px;
  height: 100%;
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
