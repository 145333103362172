@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.assessment-template {
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: 75px 1fr min-content;
  margin-bottom: 1rem;
  border-bottom: 1px solid bs.$hr-card;
  // border-bottom: 3px solid red;
  padding-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .template-type {
    font-size: 2rem;
    display: inline-block;
    text-align: center;

    :global(.assessment-type-name) {
      color: bs.$green;
      font-size: 0.8rem;
    }
  }

  .template-info {
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    column-gap: 0.5rem;
    grid-template-areas:
      'name dates'
      'author dates'
      'description description';
  }

  .template-dates {
    grid-area: dates;
    white-space: nowrap;
    position: relative;
    top: -0.5rem;

    .template-dates-table {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      font-size: 0.8rem;
      // text-align: right;
    }

    .template-dates-table-header {
      color: bs.$dark-gray;
    }
    .template-dates-table-body {
      font-weight: normal;
      // font-size: 1rem;
      min-width: 100px;
    }
  }

  .template-actions {
    // grid-area: actions;
    // display: grid;
    a {
      line-height: 1.75rem;
    }

    :global(.btn) {
      margin: 0 0 0.5rem;
      font-size: 0.8rem;
      height: 1.75rem;
      width: 1.75rem;
      min-width: 1.75rem;
    }
    // height: 0;
    // // justify-content: flex-start;
    // // align-content: flex-start;
    // // align-items: start;
    // // flex-direction: column;

    // > * {
    //   flex: 0 0 auto;
    // }
  }

  .template-author {
    font-size: 0.8rem;
    color: bs.$dark-gray;
    grid-area: author;
  }

  .template-changes-state {
    font-size: 0.8rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 0.25rem;
    color: bs.$white-color;
    background-color: transparent;
    margin-bottom: 0.5rem;

    &.unpublished {
      background-color: bs.$blue;
    }
  }

  .template-name {
    font-size: 1.5rem;
    font-weight: bold;
    grid-area: name;
  }

  .template-description {
    font-size: 0.8rem;
    grid-area: description;
  }

  .template-description-markdown {
    background: bs.$smoke-bg;
    padding: 0.5rem;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.archived-template-list {
  tr:first-child td {
    border-top: none;
  }
}

.no-date {
  color: bs.$body-subdued-font-color;
}
