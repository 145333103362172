.card-header .action-items {
  float: right;
  margin: 0.3rem 0 0 1rem;
  font-size: 1rem;

  .btn {
    margin-top: 0;
  }
}

.action-items > * {
  margin-left: 0.5em;

  &:first-child {
    margin-left: 0;
  }
}

.action-items > .btn-inline {
  margin-left: 0.25em;

  &:first-child {
    margin-left: 0;
  }
}
