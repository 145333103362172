.assessment-template {
  .assessment-template-content {
    position: relative;
  }

  .action-items {
    float: right;
    margin: -0.6rem 0 1rem 1rem;

    &.mobile {
      float: none;
    }
  }

  .assessment-template-name {
    margin: 0 0 0.75rem;
  }

  .assessment-template-type {
    float: right;
    margin: -0.6rem 0 1rem 1rem;

    .assessment-template-type-name {
      text-align: center;
      font-size: 0.9em;
      position: relative;
      top: -1.1em;
      color: $green;
      font-weight: bold;
    }

    .assessment-template-image {
      font-size: 4em;
    }
  }

  @media #{$screen} and (min-width: #{lower-bound($small-range)}) {
    .assessment-template-content {
      padding: 0 0 0 6em;
      min-height: 8em;
    }

    .assessment-template-type {
      position: absolute;
      top: -0.4em;
      left: 0em;
      float: none;
      margin: 0 0 0 0;
    }
  }
}
