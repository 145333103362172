.btn-inline {
  margin: 0 0 0 0.5rem;
  font-size: 0.8rem;
  padding: 0.15em 0.75em;
}

.btn-action-alt {
  &.btn-primary {
    @include btn-action-alt-styles($primary-color, $primary-states-color);
  }

  &:last-of-type {
    margin-right: 0.25rem;
  }
  &:first-of-type {
    margin-right: 0.25rem;
  }
}

.btn-ghost {
  &.btn-primary {
    @include btn-ghost-styles($primary-color, $primary-states-color);
  }
  &.btn-success {
    @include btn-ghost-styles($success-color, $success-states-color);
  }
  &.btn-info {
    @include btn-ghost-styles($info-color, $info-states-color);
  }
  &.btn-warning {
    @include btn-ghost-styles($warning-color, $warning-states-color);
  }
  &.btn-danger {
    @include btn-ghost-styles($danger-color, $danger-states-color);
  }
  &.btn-neutral {
    @include btn-ghost-styles($white-color, $white-color);
  }
}

.btn-light {
  @include button-variant($light-gray, $light-gray);
}

@each $color,
  $value
    in (
      'primary': $primary-color,
      'secondary': $secondary-color,
      'success': $success-color,
      'info': $info-color,
      'danger': $danger-color,
      'light': $light-gray
    )
{
  .btn-outline-#{$color} {
    background: transparent;
    padding: #{$padding-btn-vertical - 1px} #{$padding-btn-horizontal - 1px};
    border: 1px solid;

    &.btn-inline {
      padding: calc(0.15em - 1px) calc(0.75em - 1px);
    }

    &.btn-lg {
      padding: #{$padding-large-vertical -
        1px}
        #{$padding-large-horizontal -
        1px};
    }

    &.btn-sm {
      padding: #{$padding-small-vertical -
        1px}
        #{$padding-small-horizontal -
        1px};
    }

    &:focus {
      background: transparent;
      color: $value;
    }

    @include button-outline-variant($value);
  }
}

.btn-hero {
  font-size: 1.8em;
  border-radius: 0.25rem;
  padding: 15px 48px;
}

.btn.btn-link {
  color: $primary-color;
  padding: 0;

  &.btn-info {
    color: $info-color;

    &:hover {
      color: darken($info-color, 10%);
    }
    &:focus,
    &:active {
      color: $info-color;
    }
  }

  &.btn-default {
    color: $body-color;

    &:hover {
      color: darken($body-color, 10%);
    }
    &:focus,
    &:active {
      color: $body-color;
    }
  }

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background-color: $transparent-bg;
    color: $primary-color;
    text-decoration: underline;
    box-shadow: none;
  }
}

.btn-inline-action {
  margin-left: 0.3em;
  color: #868e96;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    background-color: #868e96;
    color: $white-color;
  }

  // offset this icon because it's visually unbalanced
  .fa-edit {
    position: relative;
    left: 0.1em;
  }

  &.btn-danger {
    color: $danger-color;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
      background-color: $danger-color;
      color: $white-color;
    }
  }
}

// HACK: couldn't find why this was being overridden, so adding specificity
.btn.btn-secondary {
  color: $white-color;
  background-color: $gray-600;
  border-color: $gray-600;

  &:hover,
  &:active {
    background-color: darken($gray-600, 7.5%);
    border-color: darken($gray-600, 7.5%);
  }
}

.btn-add {
  color: $black-color;

  .icon {
    font-size: 1.2em;
    color: $primary-color;
    transition: color 0.15s, fill 0.15s;
    margin-bottom: 0.3rem;
  }

  &:hover {
    .icon {
      color: $white-color;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .btn.full-width-sm {
    width: 100%;
  }
}
