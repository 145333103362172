.participation-tree {
  .icon-invites,
  .icon-assessment-date {
    color: $blue;
  }

  .participation-tree-node {
    stroke: $chart-link-color;
    stroke-width: 2;
    fill: transparent;
  }
  .participation-tree-node.null {
    stroke: $no-response-marker-color;
    fill: $black-color;
  }

  .root-node {
    .node-name {
      fill: $orange;
    }
  }
  .group-node {
    .node-name {
      fill: $orange;
    }
  }
  .team-node {
    .node-name {
      fill: $white;
    }
  }

  .node-fill-not-started {
    fill: adjust-color($blue, $lightness: 15%, $saturation: -25%);
  }
  .node-fill-partial {
    fill: $yellow;
  }
  .node-fill-completed {
    fill: $green;
  }
}

.node-popover-header,
.node-popover-body {
  color: $body-color;
}

.node-popover-action {
  button,
  a {
    font-size: 14px;
  }
}
