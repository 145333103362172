.detached-nav-bar {
  position: fixed;
  width: 100%;
  transition: width 0.35s;
  bottom: 0;
}

// @media #{$large-up} {
//   //@media (min-width: 992px) {
//   .detached-nav-bar {
//     width: calc(100vw - 260px);
//   }
//   .sidebar-mini .detached-nav-bar {
//     width: calc(100vw - 80px);
//   }
// }

.has-bottom-bar {
  padding-bottom: 6rem;
}
.bottom-bar.navbar {
  background: url(/images/cartographer.png) -35px -200px repeat;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0;
  position: absolute;

  a:not(.btn):not(.dropdown-item) {
    color: $purple;
    padding: 0.5em 0.25em;
    font-size: 2rem;

    &.disabled,
    &.disabled:hover {
      color: adjust-color($purple, $saturation: -30%, $lightness: 20%);
      cursor: default;
    }
  }

  @media #{$medium-up} {
    a:hover {
      color: $blue;

      &.nav-qr svg path {
        fill: $blue;
      }
    }

    a:not(.btn):not(.dropdown-item):hover {
      color: $blue;
    }
  }

  .nav-items {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .nav-item {
    display: inline-block;
  }

  @media screen and (max-width: #{$xlarge-breakpoint - 1px}) {
    .nav-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  button,
  .button {
    margin: 0;
  }

  .nav-qr {
    position: relative;

    svg.qr-code {
      width: 50%;
      height: 50%;
      position: absolute;
      top: 25%;
      left: 25%;
      background-color: transparent;

      path {
        fill: $purple;
      }
    }
  }
}
