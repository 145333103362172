.configure-survey-assessment-header {
  .card-header {
    label {
      margin-bottom: 0;
    }
    .card-title {
      margin: 0;

      small {
        display: block;
        margin-top: -0.4em;
      }
    }
  }
}

#configure-survey-assessment {
  .question-sets {
    padding-left: 0;
  }

  .question-set {
    list-style: none;
  }

  .question {
    list-style: disc;
    font-size: 0.8rem;
  }

  .question-set-header {
    line-height: 1rem;
    font-size: 0.75rem;
  }

  .question-count {
    float: right;
    text-align: right;
    margin: 0 0 0.5rem 1rem;

    .btn-link {
      margin: 0 0.5px;
      line-height: calc(1rem - 2px);
      font-size: 0.75rem;
      border: 0px solid transparent;
      display: inline;
      vertical-align: baseline;
    }
  }

  .question-set {
    border-bottom: 1px solid $hr-card;
    padding-bottom: 1em;
    margin-bottom: 1em;

    &:last-of-type {
      border-bottom: 0px solid transparent;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .question-set-name {
    margin-bottom: 0.5rem;
  }

  .question-set-category {
    display: inline-block;
    padding: 0 0.4rem;
    border-radius: $border-radius;
    color: $white;
    transition: background-color 0.5s;
  }

  .category-pallete {
    width: 1em;
    height: 1em;
    margin: 0 0.5em 0 0;
    position: relative;
    top: 0.25em;
    float: right;
    transition: background-color 0.5s;
    border-radius: $border-radius;
  }

  .question-set {
    transition: all 0.5s;
    opacity: 1;
    overflow: hidden;

    &.removed {
      color: $body-subdued-font-color;
    }
  }

  .category-selector {
    transition: color 0.5s;
  }
  .category-selector.muted {
    color: $body-subdued-font-color;
  }
}
