fieldset legend small {
  font-size: 60%;
  display: inline-block;
  margin-left: 1em;
}

.tiny {
  font-size: 50%;
}

h6.card-title {
  margin-bottom: 0;
}

.card-title a {
  color: $body-color;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.primary {
  color: $primary-color;
}

.success {
  color: var(--success);
}

.info {
  color: var(--info);
}

.warning {
  color: var(--orange);
}

.danger {
  color: var(--danger);
}

.muted {
  color: var(--gray);
  font-weight: 100;
}

.text-sm {
  font-size: 0.85rem;
}
