@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.variability {
  &.high-variance {
    color: bs.$danger-color;
  }

  &.medium-variance {
    color: bs.$warning-color;
  }

  &.na-variance {
    color: bs.$body-subdued-font-color;
  }
}
