.participant-bar {
  height: 1.4em;
  font-size: 1.2rem;
  margin-top: 0.25em;

  .participant {
    transition: font 0.2s ease, margin 0.2s ease;
    display: inline-block;
  }

  .participant:hover {
    font-size: 1.5em;
    margin-top: -0.5em;
  }

  .vote-none {
    color: $blue;
  }
}
.tip-participant.vote-none {
  color: $blue;
}
