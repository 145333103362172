@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.root {
  color: white;
}

.content {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.titleText {
  display: flex;
  justify-content: center;
}

.plans {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: center;
}

.billingCycle {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.toggle {
  position: relative;
  height: 1.4em;
  width: 3em;
  border-radius: 0.7em;
  cursor: pointer;

  &::before {
    width: 3em;
    height: 1.4em;
    border-radius: 0.7em;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: bs.$blue;
    border: 1px solid #fff;
    content: '';
  }

  &::after {
    width: calc(1.4em - 4px);
    height: calc(1.4em - 4px);
    border-radius: calc(0.7em - 2px);
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #fff;
    border: 1px solid bs.$blue;
    content: '';
    transition: left ease-in-out 0.15s;
  }

  &.checked {
    border-radius: 1px;

    &::after {
      left: calc(1.6em + 2px);
    }
  }
}

.toggleButton {
  margin: 1px 0 0;
  padding: 0;
  color: #fff !important;
  font-size: 1rem;

  &.selected {
    text-decoration: underline;
    color: bs.$blue !important;
  }
}

.lightMode {
  color: #2c2c2c;

  h2 {
    color: #2c2c2c;
  }

  .toggle {
    &::before {
      filter: brightness(90%);
    }
  }

  .toggleButton {
    color: #888888 !important;
    &.selected {
      color: bs.$blue !important;
      filter: brightness(80%);
    }
  }
}

.children {
  margin: -1rem auto 2rem auto;
}
