.invitee-list .invitee {
  padding: 15px 0;
  border-bottom: 1px solid #dee2e6;
  display: grid;
  grid-template-columns: 1fr 200px 150px;
  grid-template-areas: 'email status actions';
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  align-items: center;
}

.invitee-list .invitee:last-child {
  border-bottom: none;
}

.invitee-list .email {
  grid-area: email;
}

.invitee-list .status {
  grid-area: status;
}

.invitee-list .actions {
  grid-area: actions;
  text-align: right;
}

@media screen and (max-width: 700px) {
  .invitee-list .invitee {
    display: grid;
    grid-template-columns: 1fr 150px;
    grid-template-areas:
      'email actions'
      'status .';
  }
}
