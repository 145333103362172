.assessment-review-header {
  margin-top: -30px;

  .details {
    .actions {
      margin-top: 1em;

      @media #{$medium-up} {
        text-align: right;
      }
    }

    .timestamp {
      position: relative;
      top: -0.5em;
      margin-bottom: 0.5em;
    }
  }
}

.survey-assessment .assessment-review-header {
  @media #{$medium-up} {
    .participation-stats {
      margin-bottom: -300px;
    }
  }
}
