@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.chart-grid {
  display: grid;
  grid-template:
    'lollipop'
    'lines'
    'deets';

  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  column-gap: 1.5rem;
}

.lollipop {
  grid-area: lollipop;
}

.lines {
  grid-area: lines;
}

.lollipop,
.lines {
  margin: 0 -10px;

  label {
    margin-left: 10px;
  }
}

.deets {
  grid-area: none;
  display: none;
  overflow-y: scroll;
  max-height: 448px;
}

.question-selectors {
  margin-top: 1rem;

  ol {
    display: grid;
    padding: 0;
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    gap: 0.3rem;
  }
  li {
    aspect-ratio: 1/1;
    display: block;

    h4 {
      font-size: 0.6rem;
    }
  }
}
@media (min-width: 700px) {
  .chart-grid {
    grid-template:
      'lollipop deets'
      'lines deets';

    grid-template-columns: minmax(300px, 1fr) minmax(300px, 325px);
  }
  .deets {
    grid-area: deets;
    display: block;
  }
}

.question-selector {
  background-color: rgba(bs.$blue, 0.1);
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0.3rem;
  overflow: hidden;
  word-break: break-word;

  &.transient {
    background-color: rgba(bs.$orange, 0.2);
  }

  &.locked {
    background-color: rgba(bs.$blue, 0.4);
  }
}

.glyph {
  .dot {
    fill: bs.$black-color;
  }

  .lolli1 {
    fill: bs.$green;
  }

  .lolli2 {
    fill: #f0cf2b;
  }
}
