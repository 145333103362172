.question-set-question {
  margin-bottom: 1.75em;
}

.question-text {
  margin-bottom: 1em;
  font-size: 1.25em;
}

label.form-check-label {
  color: $black-color;
}

.answer {
  border: 1px solid transparent;
  padding: 0.125em 0.375em;

  label {
    cursor: pointer;
  }

  &.form-group {
    margin-bottom: 0;
  }
  .form-check-label {
    padding-left: 1.5rem;
  }
  .form-check-input {
    margin-left: -1.5rem;
    margin-top: 0.2rem;
  }
}
