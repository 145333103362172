.hero-badge {
  text-align: center;
  max-width: $small-breakpoint;
  margin: 0 auto;

  .hero-badge-icon {
    margin: 0.5rem 0;
    font-size: 125px;
    line-height: 1;
  }

  .hero-badge-title {
    font-size: 2.5em;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
}

@media #{$medium-up} {
  .hero-badge {
    .hero-badge-icon {
      margin: 1.5rem 0;
      font-size: 200px;
    }
  }
}
