@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.header {
  display: flex;
  justify-content: space-between;
  background: bs.$black-color;
  color: bs.$white-color;
  padding: 10px;
  align-items: center;
  height: bs.$minimal-layout-header-height;

  .logo {
    font-size: 1.1em;
    .title {
      display: inline-block;
      color: bs.$white-color !important;
    }
    .icon {
      color: bs.$blue;
    }
  }
}

.profileDropdownToggle {
  background: transparent;
  padding: 0.5rem 0.7rem;
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
