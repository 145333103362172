@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.team-member-card {
  margin: 0 0 15px;
  padding: 15px;
  border-radius: bs.$border-radius-small;
  display: grid;
  grid-template-columns: 48px repeat(2, minmax(12em, 30em)) min-content min-content;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'header header header'
    'avatar name email'
    'avatar role allocation'
    '. actions actions';
  grid-column-gap: 1em;
  border: 1px solid bs.$hr-card;
  background-color: bs.$smoke-bg;
}

@media only screen and (min-width: 1200px) {
  .team-member-card {
    grid-template-columns: 48px repeat(2, minmax(12em, 1fr));
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'header header header header header '
      'avatar name email role allocation'
      '. actions actions actions actions';
  }
}

// $small-up: $screen;
// $small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';

// $medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
// $medium-only: '#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})';

// $large-up: '#{$screen} and (min-width:#{lower-bound($large-range)})';
// $large-only: '#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';

// $xlarge-up: '#{$screen} and (min-width:#{lower-bound($xlarge-range)})';
// $xlarge-only: '#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})';

.team-member-avatar {
  grid-area: avatar;
  padding-top: 1.4rem;
}

.team-member-avatar-image {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  color: bs.$white-color;
}

.team-member-name {
  grid-area: name;
}
.team-member-role {
  grid-area: role;

  :global(.combobox) {
    width: 15em;
  }
}

.team-member-email {
  grid-area: email;
}

.team-member-allocation {
  grid-area: allocation;
}

.team-member-actions {
  grid-area: actions;
  text-align: left;
}
.form-header {
  grid-area: header;
}

.team-member-card:global(.edit) {
  grid-template-columns: 48px 1fr 1fr;
  grid-template-rows: auto 16px auto auto;
  grid-template-areas:
    'avatar name allocation'
    'avatar email allocation'
    '. role allocation'
    '. actions actions';
  margin-bottom: 0;

  .team-member-name {
    padding-top: 1.4rem;
    font-size: 1.2rem;
    margin-bottom: -0.3rem;
  }

  .team-member-email {
    font-size: 0.8rem;
    color: bs.$body-subdued-font-color;
  }

  .team-member-role {
    padding-top: 0.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .team-member-card:global(.edit) {
    grid-template-columns: 48px repeat(2, minmax(12em, 1fr)) min-content;
    grid-template-rows: auto 31px auto;
    grid-template-areas:
      'avatar name role allocation'
      'avatar email role allocation'
      '. actions actions actions';

    .team-member-role {
      padding-top: 0;
    }
  }
}
