.allocation {
  .btn.btn-secondary {
    margin: calc(-0.3em - 1px);
    padding: calc(0.3em + 1px);
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $blue;
      background-color: transparent;
    }
  }

  .dropdown-toggle::after {
    color: $blue;
  }

  .dropdown-menu {
    height: 0;
    overflow: hidden;
  }

  &.show > .btn.btn[aria-haspopup='true'] {
    border: 1px solid $blue;
    background-color: $white-bg;
  }

  &.show .dropdown-menu {
    -webkit-transform: translate3d(5px, calc(0.3em + 1px), 0) !important;
    transform: translate3d(calc(0.3em + 1px), calc(0.3em + 1px), 0) !important;
    height: auto;
    overflow: auto;
  }

  &.allocation-state-view {
    .dropdown-toggle::after {
      opacity: 0;
    }

    .dropdown-toggle:hover::after {
      opacity: 1;
    }

    &.show .dropdown-toggle::after {
      opacity: 1;
    }
  }

  .harvey-ball-fill {
    fill: $blue;
  }

  .harvey-ball-outline {
    fill: none;
    stroke: $blue;
    stroke-width: 2;
  }

  .harvey-ball-fill-null {
    fill: $no-response-hash-color-light;
  }
  .harvey-ball-fill-null-stripes {
    fill: $no-response-hash-color-dark;
    // fill: transparent;
  }
}

.allocation-option {
  display: grid;
  grid-template-columns: 2em 1fr;
  align-items: center;
}
