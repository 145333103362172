.participant-bar {
  font-size: 0.65rem;
}

.detail-panel {
  margin-top: 0.3rem;
}

.question-scores {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, min-content));
  column-gap: 6px;
}
