.full-page-loading {
  text-align: center;

  .loading {
    font-size: 15rem;
    padding-top: 80px;
    color: $background-impression-color;
  }
}

.loading-message {
  display: flex;

  .media-body {
    margin: auto;
  }
}
