.drag-handle {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: $body-subdued-font-color;
  cursor: grab;

  &:focus {
    outline: none;
  }
}
