@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.year-underlay {
  .year:nth-child(even) rect {
    fill: rgba(#fff, 0.05);
  }
  .year:nth-child(odd) rect {
    fill: none;
  }
}
