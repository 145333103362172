@use '/src/styles/greenleaf/bootstrap_variables' as bs;

:global(.editor-input) {
  button {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: text;
  }

  .notEditing.multiLine {
    white-space: pre-wrap;
    text-align: left;
  }

  .empty {
    font-style: italic;
    opacity: 0.7;
    color: bs.$text-muted;
  }

  textarea {
    height: 80px;
  }

  input,
  textarea {
    width: 100%;
  }

  &:global(.is-invalid) :global(.invalid-feedback) {
    display: block;
  }

  :global(.focus-container) {
    margin: -3px -5px;
  }
}
