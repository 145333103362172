.question-results-legend {
  top: 0;
  background: rgba($white, 0.9);
  padding: 0 0 0.5rem 0;
  font-size: 0.8rem;

  .legend-items {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .legend-item {
    display: inline-grid;
    grid-template-columns: 31px 1fr;
    padding: 0.1rem 0.3rem;
    margin: -0.1rem -0.3rem -0.1rem 1.2rem;
    border-radius: 0.2rem;
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
      background-color: #f1e2cd;
      border-color: $orange;

      .spacer,
      .inner-circle {
        fill: #f1e2cd;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &[aria-pressed='true'] {
      background-color: #bce7f0;
      border-color: $blue;
      .spacer,
      .inner-circle {
        fill: #bce7f0;
      }

      &:hover {
        border-color: $orange;
      }
    }

    .marker,
    .name {
      align-self: center;
    }
    .name {
      position: relative;
      top: 0.1em;
    }
  }

  .distribution-curve {
    stroke-width: 2px;
    fill: none;
  }

  .spacer,
  .inner-circle {
    fill: $white;
  }
}

.question-results {
  margin: 1rem 0;

  .question-results-wrapper {
    position: relative;
    padding-left: 10px;

    &:before {
      content: ' ';
      height: calc(100% + 6px);
      width: 2px;
      background-color: $dark-gray;
      display: block;
      position: absolute;
      top: -3px;
      left: 0px;
    }
  }

  .question-text {
    min-height: 3em;
    font-size: 0.8rem;
  }

  .cohort-curve {
    // opacity: 0.25;
    filter: contrast(calc(1 / 7)) brightness(1.75);
  }

  .primary-results-wrapper {
    position: relative;

    .no-response-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      color: $no-response-marker-color;
    }

    .primary-results-overlay {
      position: absolute;
      top: 0.45em;
      right: 0.3em;
      color: $no-response-marker-color;
      font-size: 0.6rem;
    }
  }

  .primary-results {
    .distribution-curve {
      stroke-width: 2px;
      fill: none;
    }

    .combined-results {
      stroke: $blue;
    }

    .vote-count-glyph {
      .bar {
        fill: $blue;
      }
      .spacer {
        fill: $white;
      }
      .outer-ring {
        fill: $blue;
      }
      .inner-circle {
        fill: $white;
      }
    }

    .axis-y-line {
      stroke: #c8c8c8;
      stroke-width: 2px;
    }

    .axis-y-tick {
      font-family: $sans-serif-family;
      font-size: 0.6rem;

      .axis-y-label {
        fill: $dark-gray;
      }

      .visx-line {
        stroke: #c8c8c8;
      }
    }
  }

  .answers {
    display: grid;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    .answer {
      position: relative;
      overflow: hidden;
    }

    .answer-text {
      word-break: keep-all;
      hyphens: auto;
      font-size: 0.6rem;
      text-align: center;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      &.rotate {
        position: relative;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        writing-mode: vertical-rl;
        text-orientation: mixed;
        max-width: 100%;
        max-height: 60px;
        -webkit-line-clamp: 1;
        overflow: hidden;

        text-align: left;
        // transform: rotate(90deg);
        // transform-origin: 0 50%;
        // position: absolute;
        // left: 50%;
      }
    }
  }
}
