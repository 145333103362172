.chart-neutral-line {
  stroke: $chart-stroke-color;
  stroke-width: 1px;
}
.chart-highlight-line {
  stroke: $blue;
  stroke-width: 1px;
}

.chart-axis {
  stroke: $chart-stroke-color;
  stroke-width: 1.5px;

  line {
    stroke: $chart-stroke-color;
  }
}

.chart-inline-tick {
  stroke: $chart-stroke-color;
  stroke-width: 1px;
  stroke-dasharray: 3 3;
}

.chart-grid {
  stroke-dasharray: 1 6;
}

.chart-grid-dense {
  stroke-dasharray: 1 3;
  pointer-events: none;
}

.chart-link {
  stroke: $chart-link-color;
  stroke-width: 1;
  fill: none;
}

.no-response-fill {
  fill: $no-response-marker-color;
}
.no-response-stroke {
  stroke: $no-response-marker-color;
}

.tick-label {
  fill: $chart-stroke-color;
  font-family: $sans-serif-family;
}
.tick-label-highlight {
  fill: $blue;
}
