.assessment-group {
  .assessment-group-name {
    font-size: 1.3em;
    font-weight: bold;
  }

  .assessment-list {
  }

  .assessment-description {
  }

  .additional-assessments {
    font-size: 0.8em;

    a {
      color: $body-subdued-font-color;
    }
  }
}
