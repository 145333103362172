$progress-slider-border-value: 4;
$progress-slider-border-size: $progress-slider-border-value + 0px;

.progress-slider {
  margin-bottom: 1em;
  background-color: rgba(#2c2c2c, 0.9);
  margin: 0 -15px 1em;
  padding: 0.8rem 15px 0.75rem;
  z-index: 100;
  position: sticky;
  top: 0;
  color: $white;

  .progress-text {
    font-size: 0.8rem;
    line-height: 1.3rem;
    margin-bottom: 0.2rem;
  }

  .questions {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  }

  .question-set {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 0.5em;
    overflow: hidden;
    margin: 0 #{-$progress-slider-border-value + 1px};

    &:first-of-type {
      margin-left: -$progress-slider-border-value;
    }
    &:last-of-type {
      margin-right: -$progress-slider-border-value;
    }

    &.past {
      border: $progress-slider-border-size solid transparent;
      .question {
        background-color: $light-gray;
      }
    }
    &.current {
      border: $progress-slider-border-size solid $blue;
      z-index: 1;
      .question {
        background-color: $light-gray;
      }
    }
    &.future {
      border: $progress-slider-border-size solid transparent;
      .question {
        background-color: darken($light-gray, 50%);
      }
    }

    &.past,
    &.current {
      .question.answered {
        background-color: $green;
      }
    }

    &.future .question.answered {
      background-color: adjust-color(
        $green,
        $lightness: -22%,
        $saturation: 30%
      );
    }
  }

  .question {
    height: 0.8em;
    flex-grow: 1;
    flex: 1;

    &.answered {
      background-color: $green;
    }
  }
}
