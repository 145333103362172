.assessment-list-item {
  .timestamp {
    min-width: 16em;
  }

  @media #{$small-up} {
    .timestamp,
    .action-items {
      display: inline-block;
    }

    .action-items {
      margin-bottom: 0.3em;
    }
  }
}
