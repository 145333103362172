.assessment-summary {
  padding: 0;

  .assessment-date {
    color: $body-subdued-font-color;
    font-size: 1.2rem;
    margin-bottom: 0;

    svg {
      position: relative;
      top: -0.075em;
      margin-right: 0.5em;
    }
  }

  .action-items {
    display: block;
    margin-bottom: 0.25em;

    .btn:first-child() {
      margin-left: 0;
    }
  }
  @media #{$medium-up} {
    .action-items {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 0.5em;
    }
  }
}
