@mixin sidebar-overrides(
  $sidebar-color,
  $monogram-color,
  $submenu-bg,
  $submenu-active-border-color,
  $submenu-active-color
) {
  &[data-color='#{$sidebar-color}'] {
    .monogram {
      color: $monogram-color;
    }

    .nav .nav li {
      a.active {
        background-color: $submenu-bg;
        border-left: 3px solid $submenu-active-border-color;
        color: $submenu-active-color;
      }

      &.active > a {
        &:not([data-toggle='collapse']) {
          color: $submenu-active-color;
        }

        i {
          color: $submenu-active-color;
        }
      }
    }
  }
}

.perfect-scrollbar-off {
  .sidebar .sidebar-wrapper {
    overflow-x: hidden;
  }

  .main-panel {
    overflow: visible;
  }
}

.assessment-layout .main-panel {
  width: 100%;
}

.sidebar,
.off-canvas-sidebar {
  overflow-y: auto;

  .sidebar-wrapper {
    overflow: auto;
  }

  .current-workspace-name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 155px;
  }

  .workspace-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nav ul.nav {
    margin-top: 0;
  }

  .submenu {
    margin: -4px 15px 0;
    padding-top: 4px;
    background-color: rgba(#000, 0.2);
    border-radius: 0 0 $border-radius-small $border-radius-small;
  }

  .nav .nav li {
    a {
      margin: 7px 0 0;
      margin: 0;
      transition: border 0s;

      border-radius: 0;

      > *:first-child {
        transition: margin 0s;
      }

      &.active {
        box-shadow: none;
        > *:first-child {
          margin-left: -3px;
          transition: margin 0s;
        }
      }

      i,
      p {
        line-height: 26px;
      }

      // &.active {
      //   background-color: rgba(#fff, 0.15);
      //   border-left: 3px solid $faded-blue;
      //   color: #fff;
      // }
    }
    &:last-child a {
      border-radius: 0 0 $border-radius-small $border-radius-small;
    }
  }

  .user {
    .moniker {
      color: rgba($white, 0.5);
      text-transform: uppercase;
      margin: 0 0 0.5em 23px;
      font-size: 0.8rem;
      height: 1.25em;
      transition: height 300ms ease 0s, opacity 150ms ease 0s;
    }

    .nav li > a {
      text-transform: none;

      &:not([href]):not([tabindex]) {
        &:hover,
        &:focus {
          text-transform: none;
        }
      }
    }

    .nav li:first-child > a {
      margin: 10px 15px 0;
    }

    .photo {
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 10px;
      border-radius: 50%;
      margin-left: 23px;
      box-shadow: $box-shadow-raised;
      @extend .animation-transition-general;

      .mongram-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: rgba($white, 0.5);

        .monogram {
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .logo .simple-text {
    text-transform: none;
  }

  &,
  &[data-color='black'] .monogram {
    color: $black-color;
  }
  @include sidebar-overrides(
    'black',
    $info-color,
    rgba(#fff, 0.15),
    #fff,
    #fff
  );
  @include sidebar-overrides(
    'blue',
    darken($info-color, 8%),
    rgba(#fff, 0.15),
    $faded-blue,
    #fff
  );
  @include sidebar-overrides(
    'green',
    $success-color,
    rgba(#fff, 0.15),
    $faded-green,
    #fff
  );
  // @include sidebar-overrides('orange', $orange-color, rgba(#fff, 0.15), $faded-orange, #fff);
  @include sidebar-overrides(
    'red',
    $danger-color,
    rgba(#fff, 0.15),
    $faded-red,
    #fff
  );
  @include sidebar-overrides(
    'yellow',
    $warning-color,
    rgba(#fff, 0.15),
    $faded-yellow,
    #fff
  );

  //  &[data-color='blue'] .monogram {
  //   color: darken($info-color, 8%);
  // }
  // &[data-color='green'] .monogram {
  //   color: $success-color;
  // }
  &[data-color='orange'] .monogram {
    color: $orange-color;
  }
  // &[data-color='red'] .monogram {
  //   color: $danger-color;
  // }
  // &[data-color='yellow'] .monogram {
  //   color: $warning-color;
  // }
}

.sidebar-mini {
  .user .moniker {
    height: 0;
    opacity: 0;
    margin: 0 0 0 23px;
  }

  .sidebar:hover {
    .user .moniker {
      height: 1.25em;
      opacity: 1;
      margin: 0 0 0.5em 23px;
    }
  }
}

.main-panel {
  padding-bottom: 5.5em;
}
.main-panel div > .content {
  padding: 0 15px;
  min-height: auto;
}

.main-panel:has(.clear-main-footer) {
  padding-bottom: 0;
  .footer {
    display: none;
  }
}
