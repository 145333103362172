.participant {
  padding-left: 0.2rem;
  white-space: nowrap;

  &.host {
    font-size: 1.2rem;
    white-space: nowrap;

    padding-left: 0;
    margin-left: 0;

    .fa-circle {
      color: $purple;
    }
    .fa-user {
      color: #fff;
    }
    .participant-name {
      position: relative;
      top: -0.3333333rem;
      font-size: 1.3333333rem;
    }
  }

  .participant-icon {
    position: relative;
    left: -0.6rem;
    margin-right: -1.2rem;
  }

  .participant-name {
    display: inline-block;
    margin-left: 0.8em;
  }
}
