@mixin btn-ghost-styles($btn-color, $btn-states-color) {
  background-color: transparent;
  color: $btn-color;
  -webkit-transition: background-color ease-in-out 0.15s,
    color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .show > &.dropdown-toggle,
  .show > &.dropdown-toggle:focus,
  .show > &.dropdown-toggle:hover,
  .show > &.btn[aria-haspopup='true'],
  .show > &.btn[aria-haspopup='true']:focus,
  .show > &.btn[aria-haspopup='true']:hover {
    color: $btn-states-color;
    box-shadow: none;
  }

  &:not([data-action]):hover {
    box-shadow: none;
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $btn-color;
      border: 0px solid transparent;
    }
  }
}

@mixin btn-action-alt-styles($btn-color, $btn-states-color) {
  background-color: transparent;
  color: $body-action-font-color;
  -webkit-transition: background-color ease-in-out 0.15s,
    color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0.5rem;

  .icon {
    color: $btn-color;
    -webkit-transition: color ease-in-out 0.15s;
    transition: color ease-in-out 0.15s;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .show > &.dropdown-toggle,
  .show > &.dropdown-toggle:focus,
  .show > &.dropdown-toggle:hover,
  .show > &.btn[aria-haspopup='true'],
  .show > &.btn[aria-haspopup='true']:focus,
  .show > &.btn[aria-haspopup='true']:hover {
    background-color: $primary-states-color;
    color: $white-color;
    box-shadow: none;

    .icon {
      color: $white-color;
    }
  }

  &:not([data-action]):hover {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled).active {
    background-color: darken($primary-states-color, 10%);
    border-color: darken($primary-states-color, 10%);

    &:hover {
      background-color: $primary-states-color;
      border-color: $primary-states-color;
    }
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $btn-color;
      border: 0px solid transparent;
    }
  }
}
