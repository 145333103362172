.account-settings .label {
  grid-area: label;
  color: var(--gray);
  font-size: 1em;
}

.account-settings .value {
  grid-area: value;
  font-size: 1em;
}

.account-settings-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.account-settings {
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  grid-template-areas: 'label value';
}

.main-panel div > .account-settings-pricing .content {
  padding: 15px 0 15px 0;
}

.account-settings-pricing-border {
  margin: 15px 0 15px 0;
  border-radius: 0.1875rem;
  background: url(/images/cartographer.png) -35px -200px repeat;
  z-index: 2;
}

.reseller-msg {
  font-size: 0.85em;
}

.account-billing-skeleton-card {
  .skeleton-card-title {
    .skeleton-title-text {
      width: 200px;
      height: 2em;
      margin: 20px 0 0px 20px;
    }
  }

  .account-settings-container {
    .account-settings-icon {
      width: 3em;
      height: 3em;
      margin-left: 20px;
    }

    .account-settings {
      width: 100%;

      .label {
        height: 1em;
        margin: 5px 10px 5px 20px;
      }

      .value {
        height: 1em;
        margin: 5px 0 5px 10px;
      }
    }
  }
}
