.team-list tr.team:first-child td {
  border-top: 0px solid transparent;
}

.team {
  .stamp {
    margin-right: 0.5em;
  }

  &.favorite {
    .stamp {
      color: #e07979;

      svg {
        transition: all 0.2s;
        transform: rotate(23deg);
      }

      .background-layer {
        color: #f8fc0a;
        filter: none !important;
      }
    }
  }
}

.team-member-list .team-member {
  border-bottom: 1px solid $hr-card;

  &:last-of-type {
    border-bottom: none;
  }

  &:has(+ .edit-form) {
    border-bottom: none;
  }
}
