@mixin global-message-alert($type, $color) {
  &.alert-#{$type} {
    background-color: lighten($color, 40%);
    color: darken($color, 15%);
    border: 1px solid $color;
    a {
      text-decoration: underline;
      font-weight: bold;
      color: darken($color, 15%);
    }
  }
}

.global-messages {
  .alert {
    margin-bottom: 0;
    border-radius: 0;

    @include global-message-alert('success', $success-color);
    @include global-message-alert('danger', $danger-color);
    @include global-message-alert('warning', $warning-color);
    @include global-message-alert('info', $info-color);
    @include global-message-alert('primary', $primary-color);
  }
}
