textarea.form-control {
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  line-height: 1.2;
  height: calc(3.6em + 22px);

  &:focus {
    border-top: 1px solid $purple;
    border-left: 1px solid $purple;
    border-right: 1px solid $purple;
  }
}

.form-control:not([disabled])::placeholder {
  color: $body-subdued-font-color;
}

.form-control:not([disabled]):-ms-input-placeholder {
  color: $body-subdued-font-color;
}

.form-control:not([disabled])::-ms-input-placeholder {
  color: $body-subdued-font-color;
}
