// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.alert{
    border: 0;
    border-radius: $border-radius-small;
    color: $white-color;
    padding-top: .9rem;
    padding-bottom: .9rem;
    position: relative;

    &.alert-success{
        background-color: lighten($success-color, 5%);
    }

    &.alert-danger{
        background-color: lighten($danger-color, 5%);
    }

    &.alert-warning{
        background-color: lighten($warning-color, 5%);
    }

    &.alert-info{
        background-color: lighten($info-color, 5%);
    }

    &.alert-primary{
        background-color: lighten($primary-color, 5%);
    }


    i.fa,
    i.now-ui-icons{
        font-size: 20px;
    }

    .close{
        color: $white-color;
        opacity: .9;
        text-shadow: none;
        line-height: 0;
        outline: 0;
        span {
            font-weight: 100;
            font-size: 46px;
            top: 50%;
            left: 0;
            position: absolute;
        }
    }

    span[data-notify="icon"]{
        font-size: 22px;
        display: block;
        left: 19px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
    }

    button.close{
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -13px;
        width: 25px;
        height: 25px;
        padding: 3px;
    }

    .close ~ span{
        display: block;
        max-width: 89%;
    }

    &.alert-with-icon{
        padding-left: 65px;
    }
}


// .alert{
//     border: 0;
//     border-radius: 0;
//     color: #FFFFFF;
//     padding: 10px 15px;
//     font-size: 14px;
//
//     .container &{
//         border-radius: 4px;
//
//     }
//     .navbar &{
//         border-radius: 0;
//         left: 0;
//         position: absolute;
//         right: 0;
//         top: 85px;
//         width: 100%;
//         z-index: 3;
//     }
//     .navbar:not(.navbar-transparent) &{
//         top: 70px;
//     }
//
//     span[data-notify="icon"]{
//         font-size: 30px;
//         display: block;
//         left: 15px;
//         position: absolute;
//         top: 50%;
//         margin-top: -15px;
//     }
//
//     button.close{
//         position: absolute;
//         right: 10px;
//         top: 50%;
//         margin-top: -13px;
//         z-index: 1033;
//         background-color: #FFFFFF;
//         display: block;
//         border-radius: 50%;
//         opacity: .4;
//         line-height: 11px;
//         width: 25px;
//         height: 25px;
//         outline: 0 !important;
//         text-align: center;
//         padding: 3px;
//         font-weight: 300;
//
//         &:hover{
//             opacity: .55;
//         }
//     }
//
//     .close ~ span{
//         display: block;
//         max-width: 89%;
//     }
//
//     &[data-notify="container"]{
//         padding: 10px 10px 10px 20px;
//         border-radius: $border-radius-base;
//     }
//
//     &.alert-with-icon{
//         padding-left: 65px;
//     }
// }
// .alert-info{
//      background-color: $azure-navbar;
// }
// .alert-success {
//     background-color: $green-navbar;
// }
// .alert-warning {
//      background-color: $orange-navbar;
// }
// .alert-danger {
//      background-color: $red-navbar;
// }
