.radial-pie-chart {
  .bounding-circle {
    stroke: $chart-stroke-color;
    fill: none;
  }

  .wedge-divider {
    stroke: $chart-stroke-color;

    &.inner-divider {
      stroke-width: 0.5;
    }
  }

  .chart-wedge-hover-spacer {
    fill: transparent;
  }

  .chart-wedge-hover-target:hover {
    .chart-wedge-question-set {
      filter: saturate(85%) brightness(200%);
    }
  }

  .chart-grid-dense {
    stroke: $chart-stroke-color;
    fill: none;
  }

  .chart-wedge-question-set {
    filter: saturate(70%) brightness(140%);

    &.unanswered {
      fill: rgba($no-response-marker-color, 0.1);
    }
  }
}
