@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.7);
  display: grid;
  justify-content: center;
  align-content: center;
  z-index: 100;
  grid-template-columns: minmax(min-content, 400px);
}
