.chart-glyph.squad-scorecard {
  .bar {
    fill: $no-response-marker-color;
  }
  .circle-r {
    fill: $red;
  }
  .circle-y {
    fill: $yellow;
  }
  .circle-g {
    fill: $green;
  }
}

.squad-scorecard-grid {
  width: 100%;
  border-spacing: 0 5px;
  border-collapse: separate;

  thead {
    position: sticky;
    top: 0px;

    tr {
      background-color: rgba($white-color, 0.8);
    }
  }

  th,
  td {
    padding: 5px 5px;
    margin-bottom: 5px;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  tr {
    margin-bottom: 10px;
  }

  .squad-scorecard-name {
    vertical-align: middle;
    min-width: 15%;
    padding: 5px 1em;
    font-size: 0.8rem;

    .details {
      font-size: 0.8em;
    }
  }

  .squad-scorecard-question {
    text-align: center;
    vertical-align: middle;
    font-size: 0.8rem;
    text-align: center;
    width: 6.5%;

    &.squad-scorecard-header {
      vertical-align: bottom;
    }
  }

  .squad-scorecard-result {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid $no-response-marker-color;
    background-color: lighten($blue, 40%);
    margin: 0 auto;
  }
}
