@keyframes view-stack-current-push {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes view-stack-current-pop {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.child-view-selector {
  padding-bottom: 98px;
}

.child-view-selector-upshift {
  .upshift {
    position: relative;
    top: -83px;
    margin-bottom: -53px;
  }
}

.structure-children-results {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 1em;
  row-gap: 1em;

  text.team-name-stroke {
    stroke: #fff;
    stroke-width: 2;
  }

  .structure-results-group {
    grid-column-end: -1;
    grid-column-start: 1;

    .group-name {
      margin: 2rem 0 0;
    }

    &:first-child .group-name {
      margin: 0;
    }
  }
}

.structure-results-group {
  .group-name {
    font-size: 1.2rem;
  }
}

.org-tree {
  .team-name {
    fill: #fff;
  }
  .group-name {
    fill: #ffb44d;
  }
}

.assessment-bars {
  position: relative;

  .question-bar {
    cursor: pointer;

    &:hover {
      stroke: $blue;
      stroke-width: 2px;
    }
  }

  .details {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    pointer-events: none;
    font-size: 0.8rem;

    &.sub-details {
      .team-name {
        display: none;
      }
      .sub-details-text {
        display: block;
      }
    }

    .sub-details-text {
      display: none;
      background-image: linear-gradient(
        to bottom,
        rgba(#fff, 0.6),
        rgba(#fff, 0.6) 75%,
        rgba(#fff, 0)
      );
      position: relative;
      padding-bottom: 2em;
      z-index: 2;
    }
  }

  .team-name {
    text-align: right;
  }

  .question-text {
  }

  .path-descriptor {
    padding: 0 0.6rem 0 1.32rem;
    font-size: 0.6rem;
    text-indent: -0.72rem;

    svg {
      margin-right: 0.5em;
    }

    &.root {
      text-indent: 0;
      padding: 0 0.6rem;

      svg {
        display: none;
      }
    }
  }
  .path-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .view-stack-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 50%;

    &.push .view-stack-item.current {
      animation: view-stack-current-push 0.25s;
    }

    &.pop .view-stack-item.current {
      animation: view-stack-current-pop 0.25s;
    }
  }

  .view-stack-item {
    transition: transform 0.25s;
    position: absolute;
    width: 100%;
    top: 0;

    &.pre {
      transform: translateX(-100%);
    }

    &.post {
      transform: translateX(100%);
    }
  }
}

.org-recommendation-node {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.org-recommendations {
  .assessment-summary {
    margin-bottom: $margin-large-vertical;
  }

  .aggregate-history {
    margin-top: $margin-large-vertical;
  }

  .category-bar {
    display: block;
    height: 3px;
    width: 100%;
    margin-top: -4px;
    margin-bottom: 4px;
  }
}

.footnote {
  // font-weight: bold;
  font-style: italic;
  font-size: 0.8em;
}

.org-node-recommendation {
  margin-bottom: 2rem;
}

.org-chart-selector {
  padding-left: 30px;
}

.assessment-recommendations {
  .bar-1 {
    fill: #ed2228;
  }
  .bar-2 {
    fill: #f0dd2b;
  }
  .bar-3 {
    fill: #4c9745;
  }
}

.chart-glyph {
  &.assessment-recommendations {
    .bar-1 {
      fill: #ed2228;
    }
    .bar-2 {
      fill: #f0dd2b;
    }
    .bar-3 {
      fill: #4c9745;
    }
  }

  &.tree-map {
    .node-connector {
      stroke-width: 1;
      stroke: $blue;
      fill: none;
    }
    .node-root {
      fill: #f0dd2b;
    }
    .node-1 {
      fill: #4c9745;
    }
    .node-2 {
      fill: #f0dd2b;
    }
    .node-3 {
      fill: #ed2228;
    }
  }

  &.tree-map-rectangles {
    .node-connector {
      stroke-width: 1;
      stroke: $blue;
      fill: none;
    }
    .node-root-1 {
      fill: $blue;
    }
    .node-root-2 {
      fill: $yellow;
    }
    .node-1 {
      fill: $blue;
    }
    .node-2 {
      fill: $yellow;
    }
    .node-3 {
      fill: $black-color;
      stroke: $no-response-marker-color;
      stroke-width: 1;
    }
  }

  &.trend-history,
  &.trend-area {
    .axis {
      stroke-linejoin: round;
      stroke: #ccc;
      stroke-width: 2;
      fill: none;
    }

    .line-1,
    .line-2 {
      stroke-width: 2;
    }

    .line-1 {
      stroke: #4c9745;
    }
    .line-2 {
      stroke: #fde50e;
    }

    .area-1 {
      fill: #4c9745;
    }
    .area-2 {
      fill: $yellow;
    }
    .area-3 {
      fill: $purple;
    }
  }

  &.quadrant {
    .axis {
      stroke-linejoin: round;
      stroke: #ccc;
      stroke-width: 2;
      fill: none;
    }

    .circle-1 {
      fill: $purple;
    }
    .circle-2 {
      fill: #ed2228;
    }
    .circle-3 {
      fill: #f0dd2b;
    }
    .circle-4 {
      fill: #4c9745;
    }
    .circle-5 {
      fill: $blue;
    }

    .cross {
      stroke-dasharray: 1 3;
      stroke: #526067;
      stroke-width: 0.75;
    }

    .target-quadrant {
      fill: rgba($blue, 0.2);
    }
  }
}

.assessment-trend {
  display: flex;

  .assessment-trend-chart {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .active-team {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 0.8rem;
  }

  .historical-curve-group {
    transition: all 300ms;
  }

  .historical-curve-events {
    pointer-events: stroke;
    // pointer-events: visibleStroke;
  }
  .historical-curve-area {
    pointer-events: none;
  }

  .assessment-trend-groups {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 1rem;
  }

  .visx-axis .visx-line {
    stroke: #526067;
  }
  .visx-axis text {
    fill: #526067;
    font-size: 0.6rem;
  }

  .trend-group {
    display: block;
    cursor: default;

    i {
      display: inline-block;
      margin-right: 0.4em;
      width: 1rem;
      height: 1rem;
      position: relative;
      top: 0.13rem;
    }
  }
}

.org-quadrants {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 1em;
  row-gap: 1em;

  .org-group {
    grid-column-end: 3;
    grid-column-start: 1;
    grid-row: span 2;
  }

  .grid-clear {
    grid-column-end: -1;
    grid-column-start: 1;
    height: 2rem;
  }

  .visx-axis .visx-line {
    stroke: #526067;
  }
  .visx-axis text {
    fill: #526067;
    font-size: 0.6rem;
  }

  .visx-axis .visx-axis-label {
    font-size: 0.8rem;
  }

  .target-quadrant {
    fill: #dfecf3;
  }

  .highlighted {
    stroke: $red;
    stroke-width: 4px;
    paint-order: stroke;
  }

  .quadrant-divider {
    stroke: $blue;
    stroke-dasharray: 1 3;
  }

  .node-name {
    line-height: 1.6rem;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
  }

  h3.node-name {
    font-size: 1.5rem;
    padding-left: 30px;
    margin-bottom: 0.25rem;
  }

  h4.node-name {
    position: relative;
    top: 0.15rem;
  }

  .quadrant-visualization {
    perspective: 1000px;
  }

  .quadrant-visualization-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }

  .quadrant-visualization-chart,
  .quadrant-visualization-top-targets {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .quadrant-visualization-chart {
    background-color: #fff;
  }

  .quadrant-visualization-top-targets {
    transform: rotateY(180deg);

    h5 {
      font-size: 1.25em;
      margin-bottom: 0.5em;
    }
    ol {
      padding: 0 0 0 20px;
      margin: 0;
    }
  }

  .quadrant-visualization.text-large {
    .quadrant-visualization-top-targets-wrapper {
      margin-left: 20px;
      padding: 15px;
      font-size: 1rem;
    }
  }

  .quadrant-visualization-top-targets-wrapper {
    background-color: #deecf3;
    padding: 5px 8px;
    font-size: 0.8em;
    height: 100%;
  }

  .quadrant-visualization.flipped .quadrant-visualization-inner {
    transform: rotateY(180deg);
  }

  .quadrant-visualization-chart {
    position: relative;
  }

  .active-question-set {
    text-align: right;
    pointer-events: none;
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    font-size: 0.8rem;
    max-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .org-team .active-question-set {
    font-size: 0.6rem;
    top: 0.6rem;
    right: 0.75rem;
  }

  > * {
    // background-color: fuchsia;
  }
}

.assessment-picker {
  text-align: right;
  margin-top: calc(1rem + 10px);

  i {
    display: inline-block;
    border-radius: $border-radius;
    background-color: #2c2c2c;
    padding: 0.25em;
    margin: -0.3em 0.5em -0.3em 0;
    border: 2px solid #32555e;
    line-height: 0;
    position: relative;
    top: -1px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.dropdown-menu {
  padding: 0;

  > *:first-child {
    margin-top: 0;
  }
}

.dropdown-menu:before {
  display: none;
}
.dropdown > button {
  margin: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: rgba($blue, 0.4);
}

.jira-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 1em;
  row-gap: 1em;

  .group-item {
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: 2rem;
    border-bottom: 2px solid #2c2c2c;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    h3 {
      font-size: 1.4em;
      margin-bottom: 0px;
    }

    .legend {
      position: absolute;
      right: 0;
      top: 9px;
      font-size: 0.8rem;

      .item {
        display: inline-block;
        margin-left: 1rem;

        i {
          position: relative;
          top: -2.5px;
          width: 1.5rem;
          height: 3px;
          display: inline-block;
          margin-right: 0.3rem;

          &.say-do {
            background-color: $blue;
          }
          &.churn {
            background-color: $orange;
          }
          &.say {
            background-color: #ababab;
          }
          &.do {
            background-color: $green;
          }

          &.bug {
            background-color: $red;
          }
          &.maintenance {
            background-color: $purple;
          }
          &.story {
            background-color: $green;
          }
          &.spike {
            background-color: $blue;
          }
        }
      }
    }
  }

  .team-item {
    h4 {
      font-size: 0.8rem;
      margin: 0 5px;
    }
  }

  .jira-curve {
    stroke-width: 2;
  }

  .jira-curve-saydo {
    stroke: $blue;
  }

  .jira-curve-churn {
    stroke: $orange;
  }

  .jira-curve-say {
    stroke: #ababab;
  }

  .jira-curve-do {
    stroke: $green;
  }

  .chart-container {
    fill: none;
    stroke: #526067;
  }
  .visx-axis .visx-line {
    stroke: #526067;
  }
}

.area-maintenance {
  fill: $purple;
}
.area-bug {
  fill: $red;
}
.area-story {
  fill: $green;
}
.area-spike {
  fill: $blue;
}

.org-team-flip,
.org-group-flip {
  display: flex;
  font-size: 1rem;

  .btn {
    margin: 0.1rem;
  }

  .node-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .quadrant-flipper {
    flex-shrink: 0;
  }
}

.org-group-flip {
  font-size: 1.5rem;

  .quadrant-flipper {
    position: relative;
    top: -0.2em;
    margin-bottom: -0.2em;
  }
}
