@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.category-layout {
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.category-pills.sticky {
  position: sticky;
  top: 0;
  background-color: rgba(bs.$black-color, 0.8);
  z-index: 2;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: -5px;
    background-image: linear-gradient(
      rgba(bs.$black-color, 0.8),
      rgba(bs.$black-color, 0)
    );
  }
}

:global(.category-pill) {
  display: inline-block;
  padding: 0 0.4rem;
  border-radius: bs.$border-radius-small;
  line-height: 1rem;
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.question-set {
  list-style: none;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0 30px 0 0;
}

:global(.category-question-list) {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.inline-name-wrapper {
  display: inline-block;
}

.question-set-name {
  font-size: 0.7rem;
}

.name-bar {
  display: block;
  height: 3px;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 4px;
}

.bar {
  display: block;
  height: 3px;
  width: 100%;
  margin-top: -7px;
}
