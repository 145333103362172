.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 1rem 3rem;
}

@media (min-width: 1200px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(900px, 1fr));
  }
}

@media (max-width: 674px) {
  .cards {
    grid-template-columns: 1fr;
  }
}

.feature {
  margin-bottom: 2rem;
}
