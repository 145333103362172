.dark {
  color: $white-color;

  label {
    color: $white-color;
  }

  input {
    background-color: $white-bg;
  }
}
