@use '/src/styles/greenleaf/bootstrap_variables' as bs;

.tool {
  font-size: 0.8rem;
  margin: 10px inherit;
  background: white;
  padding: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  &.is-open {
    .tool-header {
      margin-bottom: 10px;
    }
  }

  :global(.card-body) {
    padding: 0;
  }

  :global(.card-title) {
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
  }

  :global(.tab-nav) {
    margin-top: 1em;
  }

  :global(.nav.nav-underline) {
    :global(.nav-item) {
      // font-size: 0.9rem;

      a,
      a:visited {
        cursor: pointer;
      }

      a:first-child {
        margin-left: 0;
      }

      a:hover {
        opacity: 0.8;
        border-bottom: 2px solid bs.$blue;
      }

      &:global(.active) a,
      a.active {
        opacity: 1;
        border-bottom: 2px solid bs.$blue;
      }

      a {
        transition: opacity 200ms, border 200ms;
        border-bottom: 2px solid transparent;
        opacity: 0.4;
        margin: 0 10px;
        padding: 0 1em;
      }
    }
  }

  :global(.markdown-input-markdown) {
    background: bs.$smoke-bg;
    font-size: bs.$font-size-small;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  :global(.custom-checkbox) {
    font-size: 1rem;
    label {
      font-size: 1em;
    }
  }
}

.tool-header {
  display: grid;
  grid-template-columns: 1fr min-content;
}

.tool-collapse-toggle {
  font-size: 0.6rem;
  position: relative;
  min-width: 2rem;

  button {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: -3px;

    svg {
      transition: transform 200ms;
    }
  }
}

.tool-body {
  font-size: 1rem;
}
