.overlay-input-group {
  position: relative;
  // .form-control, .input-group-append, .input-group-prepend, .input-group-btn {
  //   &:last-child {
  //     border-left: auto;
  //   }

  //   &:first-child {
  //     border-right: auto;
  //   }
  // }

  &.padded-layout.input-lg {
    .input-prepend-icon {
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 20px;
      color: $blue;
      margin-top: -0.8em;

      // svg[data-icon], .icon {
      // }
    }

    input[type='text'],
    input[type='password'] {
      padding-left: 2.5em;
      border-radius: $btn-round-radius;
    }
  }
}
